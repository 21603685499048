import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { IEvent } from "../events/eventsSlice";
import usersApi from "../../../services/usersApi";

export interface User {
  wallet: string;
  clientId: string;
  referrer?: string;
  isPremium: boolean;
}
interface UserState {
  user: User;
  status: string;
  error: string;
  currentFavoritesPage: number;
  hasfavoritesNextPage: boolean;
  favoritesPerPage: number;
}
const initialState: UserState = {
  user: { wallet: "", clientId: "", isPremium: false },
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: "",
  currentFavoritesPage: 1,
  hasfavoritesNextPage: true,
  favoritesPerPage: 10,
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (userData: User) => {
    const response = await usersApi.login(userData);
    return response;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message ?? "";
      });
    // .addCase(getAllFavoritesIds.fulfilled, (state, action) => {
    //   state.status = "succeeded";
    // });
  },
});

export const getPostsStatus = (state: { user: UserState }) => state.user.status;

export const { setUserStatus } = userSlice.actions;
export default userSlice.reducer;

import React from "react";
import { DisconnectButton } from "./components";

export const ChangeNetworkMessage = () => {
  return (
    <div className="flex items-center justify-center w-full flex-col px-4 py-2 !fixed top-0 bg-white z-10">
      <div className="flex gap-x-12 bg-gray-100 w-full py-3 pl-2 pr-3.5 h-headerHeight rounded-lg justify-between">
        <p className="relative flex items-center text-sm leading-tight">
          Please disconnect wallet and change network to MAINNET
        </p>
        <DisconnectButton />
      </div>
    </div>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IEvent } from "./eventsSlice";
import favoritesEventsApi from "../../../services/favoritesEventsApi";

export interface FavoriteEvent extends IEvent {}

interface FavoritesEventsState {
  favoritesEvents: FavoriteEvent[];
  favoritesEventsId: number[];
  status: string;
  error: string | null;
  isFavoriteEventStart: boolean;
  favoritesEventsCurrentPage: number;
  hasNextPage: boolean;
  favoritesEventsPerPage: number;
}

const initialState: FavoritesEventsState = {
  favoritesEvents: [],
  favoritesEventsId: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  favoritesEventsCurrentPage: 1,
  hasNextPage: true,
  isFavoriteEventStart: false,
  favoritesEventsPerPage: 10,
};

export const fetchAllFavoritesIds = createAsyncThunk(
  "user/getAllFavoritesIds",
  async (clientId: string) => {
    const response = await favoritesEventsApi.getAllFavoritesIds(clientId);
    return response;
  }
);
export const fetchAllFavorites = createAsyncThunk(
  "user/getAllFavorites",
  async (arg: { clientId: string; page: number; perPage: number }) => {
    const { page, perPage, clientId } = arg;
    const response = await favoritesEventsApi.getAllFavorites(
      clientId,
      page,
      perPage
    );
    return response;
  }
);
export const addEventToFavorites = createAsyncThunk(
  "user/addEventToFavorites",
  async (arg: { clientId: string; eventId: number }) => {
    const { eventId, clientId } = arg;
    const response = await favoritesEventsApi.addEventToFavorites(
      clientId,
      eventId
    );
    return response;
  }
);
export const deleteEventFromFavorites = createAsyncThunk(
  "user/deleteEventFromFavorites",
  async (arg: { clientId: string; eventId: number }) => {
    const { eventId, clientId } = arg;
    const response = await favoritesEventsApi.deleteEventFromFavorites(
      clientId,
      eventId
    );
    return response;
  }
);

const favoritesEventsSlice = createSlice({
  name: "favoritesEvents",
  initialState,
  reducers: {
    setIsFavoriteEventStart: (state, action) => {
      state.isFavoriteEventStart = action.payload;
    },
    setFavoritesEventsCurrentPage: (state, action) => {
      state.favoritesEventsCurrentPage = action.payload;
    },
    setFavoritesEventsPerPage: (state, action) => {
      state.favoritesEventsPerPage = action.payload;
    },
    setFavoritesEvents: (state, action) => {
      state.favoritesEvents = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllFavorites.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAllFavorites.fulfilled, (state, action) => {
        const totalPages = Math.ceil(
          action.payload.totalCount / state.favoritesEventsPerPage
        );
        state.status = "succeeded";
        state.hasNextPage = state.favoritesEventsCurrentPage < totalPages;
        state.favoritesEventsCurrentPage += 1;

        state.favoritesEvents = [
          ...state.favoritesEvents,
          ...action.payload.data,
        ];
      })
      .addCase(fetchAllFavorites.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchAllFavoritesIds.fulfilled, (state, action) => {
        state.favoritesEventsId = action.payload;
      })
      .addCase(addEventToFavorites.fulfilled, (state, action) => {
        state.favoritesEventsId.push(action.payload);
      })
      .addCase(deleteEventFromFavorites.fulfilled, (state, action) => {
        const favoritesEvents = state.favoritesEvents.filter(
          (favoriteEvent) => favoriteEvent.id !== action.payload
        );
        const favoritesEventsId = state.favoritesEventsId.filter(
          (favoriteEventId) => favoriteEventId !== action.payload
        );
        state.favoritesEventsId = favoritesEventsId;
        state.favoritesEvents = favoritesEvents;
      });
  },
});
export const {
  setIsFavoriteEventStart,
  setFavoritesEventsCurrentPage,
  setFavoritesEventsPerPage,
  setFavoritesEvents,
} = favoritesEventsSlice.actions;

export const selectAllFavoritesEvents = (state: {
  favoritesEvents: FavoritesEventsState;
}) => state.favoritesEvents.favoritesEvents;
export const selectAllFavoritesEventsId = (state: {
  favoritesEvents: FavoritesEventsState;
}) => state.favoritesEvents.favoritesEventsId;
export const getFavoritesEventsStatus = (state: {
  favoritesEvents: FavoritesEventsState;
}) => state.favoritesEvents.status;

export const getFavoritesEventsError = (state: {
  favoritesEvents: FavoritesEventsState;
}) => state.favoritesEvents.error;
export const selectHasNextPage = (state: {
  favoritesEvents: FavoritesEventsState;
}) => state.favoritesEvents.hasNextPage;

export const selectIsFavoriteEventStart = (state: {
  favoritesEvents: FavoritesEventsState;
}) => state.favoritesEvents.isFavoriteEventStart;

export const selectCurrentPage = (state: {
  favoritesEvents: FavoritesEventsState;
}) => state.favoritesEvents.favoritesEventsCurrentPage;

export const selectPerPage = (state: {
  favoritesEvents: FavoritesEventsState;
}) => state.favoritesEvents.favoritesEventsPerPage;

export const selectFavoriteEventById = (
  state: { favoritesEvents: FavoritesEventsState },
  eventId: number
) =>
  state.favoritesEvents.favoritesEvents.find((event) => event.id === eventId);

export default favoritesEventsSlice.reducer;

import React from "react";
import { TypesList } from "./components";
import useTypesData from "./useTypesDataю";

export const TypesComponent = () => {
  const { types, typesStatus, error, selectedTypesId, handleTypeClick } =
    useTypesData();

  return (
    <TypesList
      types={types}
      typesStatus={typesStatus}
      error={error}
      selectedTypesId={selectedTypesId}
      handleTypeClick={handleTypeClick}
    />
  );
};

import { TonConnectButton, useTonConnectUI } from "@tonconnect/ui-react";
import { FriendsList } from "../../containers/FriendsList";
import { InviteText } from "../../components/InviteText";
import { InviteBonuses } from "../../components/InviteBonuses";
import { InvitesButtons } from "../../components/InvitesButtons";
import { Percentages } from "../../components/Percentages";

export const Friends = () => {
  return (
    <>
      <div className="p-4">
        <InviteText />
        <InviteBonuses />
        <Percentages  />
        {/* <FriendsList /> */}
      </div>
      <InvitesButtons />
    </>
  );
};

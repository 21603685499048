import React, { useEffect, useState } from "react";

export const PendingDots = () => {
  const [activeDot, setActiveDot] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDot((prevState) => (prevState + 1) % 3);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="leading-none flex space-x-1">
      <span className={`${activeDot >= 0 ? "opacity-100" : "opacity-0"}`}>
        .
      </span>
      <span className={` ${activeDot >= 1 ? "opacity-100" : "opacity-0"}`}>
        .
      </span>
      <span className={` ${activeDot >= 2 ? "opacity-100" : "opacity-0"}`}>
        .
      </span>
    </div>
  );
};

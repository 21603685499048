import React from "react";

export const Timer = ({
  hours,
  minutes,
  seconds,
}: {
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  return (
    <div className="mt-4 text-gray-700 text-center">
      <p className="text-lg font-semibold">Next claim available in:</p>
      <p className="text-2xl font-bold">
        <span className="text-blue-500">{hours}</span>h{" "}
        <span className="text-blue-500">{minutes}</span>m{" "}
        <span className="text-blue-500">{seconds}</span>s
      </p>
    </div>
  );
};

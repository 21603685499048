import React from "react";
import { Bet } from "../../../../../../../../app/features/bet/betSlice";
import { BetOutcome } from "./components";
import { RootState } from "../../../../../../../../app/store";
import { useSelector } from "react-redux";
import { CURRENCY } from "../../../../../../../../app/features/currency/currencySlice";

export const BetDetails = ({
  bet,
  coefficient,
  potensialWinning,
  win,
}: {
  bet: Bet;
  coefficient: string | number;
  potensialWinning: number;
  win: boolean | undefined;
}) => {
  const { selectedCurrencyId } = useSelector(
    (state: RootState) => state.currency
  );

  console.log(bet);
  return (
    <>
      <div className="flex justify-between items-start">
        <div className="flex flex-col">
          <div className="flex items-center mb-1.5 gap-2">
            <span className="text-eventBorder font-normal text-xs">
              {bet.event.name}
            </span>
            <BetOutcome win={win} />
          </div>
          <div className="flex items-center">
            <div className="flex items-center">
              {bet.pool.teamImage && (
                <div className="flex bg-teamImageBg h-7 w-7 justify-center items-center rounded-md mr-2">
                  <img
                    src={`https://assets.b365api.com/images/team/s/${bet.pool.teamImage}.png`}
                    alt={bet.pool.name}
                    className="w-5 h-5 rounded-md p-1"
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col justify-center">
              <span className="text-sm font-medium text-gray-900 leading-tight">
                {bet.event.matchName}
              </span>
              <span className="text-sm font-medium text-gray-400 leading-tight">
                {bet.pool.name}
              </span>
            </div>
          </div>
        </div>
        <div className="flex bg-bgCoefBet rounded  justify-center">
          <span className="text-sm font-medium text-pullCoef px-2 py-[7px]">
            {coefficient}
          </span>
        </div>
      </div>
      <div className="border-t border-betLineColor m-2 -ml-2 -mr-2"></div>
      <div className="flex flex-col">
        <div className="flex items-center justify-between leading-none">
          <div className="text-xs text-betDataColor">Stake:</div>
          <span
            className={`text-right ${
              bet.pool.isWin === undefined
                ? "text-slate-800"
                : bet.pool.isWin === null
                ? "text-[#df4449]"
                : "text-slate-800"
            }  text-xs font-bold font-['SF Pro Text'] leading-3 whitespace-nowrap`}
          >
            {bet.pool.isWin === null ? "-" : ""}
            {bet.value.toFixed(2)} {CURRENCY[selectedCurrencyId].toUpperCase()}
          </span>
        </div>
        {bet.pool.isWin && (
          <div className="flex items-center justify-between leading-none">
            <div className="text-xs text-betDataColor">Winnings:</div>
            <span
              className={`text-xs font-bold text-greenWinColor  whitespace-nowrap`}
            >
              {potensialWinning.toFixed(2)}{" "}
              {CURRENCY[selectedCurrencyId].toUpperCase()}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

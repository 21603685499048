// useTonBalance.ts
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Address, fromNano } from "@ton/core";
import { useTonClient } from "./useTonClient";
import { AddressType } from "tonweb";
import { setTonBalance } from "../app/features/wallet/walletSlice";

const useTonBalance = (address: string) => {
  const dispatch = useDispatch();
  const { client } = useTonClient();

  let previousAddress: string | null = null;
  let balanceRetrievalInterval: any | null = null;

  useEffect(() => {
    async function getBalance() {
      if (client && address) {
        try {
          const balancePromise = client.getBalance(
            Address.parse(address) as unknown as AddressType
          );
          const balance = await balancePromise;

          let parsedBalance = balance ? fromNano(balance) : null;

          if (parsedBalance) {
            if (address !== previousAddress) {
              dispatch(setTonBalance(+(+parsedBalance).toFixed(4)));
              previousAddress = address;
            }
          }
        } catch (e) {
          console.error("Error getting balance:", e);
        } finally {
          if (!address) {
            clearTimeout(balanceRetrievalInterval);
            balanceRetrievalInterval = null;
          }
        }
      }

      if (address) {
        balanceRetrievalInterval = setTimeout(getBalance, 5000);
      }
    }

    getBalance();

    return () => {
      if (balanceRetrievalInterval) {
        clearTimeout(balanceRetrievalInterval);
      }
    };
  }, [client, address, dispatch]);

  return null;
};

export default useTonBalance;

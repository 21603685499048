import React from "react";
import useBetsData from "./useBetsData";
import { BetsList } from "./components";

export const Bets = () => {
  const {
    bets,
    betsStatus,
    error,
    showScrollButton,
    handleScroll,
    scrollToTop,
  } = useBetsData();

  return (
    <BetsList
      bets={bets}
      betsStatus={betsStatus}
      error={error}
      showScrollButton={showScrollButton}
      handleScroll={handleScroll}
      scrollToTop={scrollToTop}
    />
  );
};

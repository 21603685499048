import React, { useState, useEffect, useRef } from "react";
import useBetData from "./useBetData";
import ConnectWalletPage from "../../../../../pages/ConnectWalletPage";
import SimpleKeyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { CHAIN } from "@tonconnect/ui-react";
import moment from "moment";
import { CURRENCY } from "../../../../../app/features/currency/currencySlice";
import { EventStatus } from "../../../../../app/features/events/eventsSlice";
import { BetInput, KeyboardComponent, QuickBets } from "./components";
import { TransactionPending } from "../../../../../containers/TransactionPending";
import { PlaceBetAnimation } from "../../../../../components";

export const PlaceBetFooter = () => {
  const {
    showKeyboard,
    setShowKeyboard,
    isMobile,
    chain,
    value,
    setValue,
    address,
    preBet,
    activeBet,
    currencies,
    currencyStatus,
    error,
    selectedCurrencyId,
    IsEventStart,
    balance,
    currencyName,
    MIN_BET,
    MAX_BET,
    isLowBalance,
    isNotMinBet,
    isNotMaxBet,
    isValid,
    isValidNumber,
    event,
    closeBet,
    handleCurrency,
    handleFastBet,
    calculatePotentialWinnings,
    keyboardOptions,
    handleChangeInput,
    keyboard,
    handleChangeKeyboard,
    placeBet,
    isLoading,
    setIsLoading,
    isVideoPlaying,
  } = useBetData();

  const quickBets = [10, 20, 30];

  const [isCurrencyListVisible, setIsCurrencyListVisible] = useState(false);

  const toggleCurrencyList = () => {
    setIsCurrencyListVisible(!isCurrencyListVisible);
  };

  const handlePlaceBet = async () => {
    // Виклик функції для розміщення ставки
    await placeBet(
      value ? value : 0,
      preBet.eventId,
      preBet.poolId,
      event?.eventWallet as string
    );
  };

  const renderedCurrencies = currencies.map((c) => (
    <button
      key={c.currency}
      className={`flex flex-grow cursor-pointer h-full px-2 py-1 leading-none text-sm font-medium text-gray-700 items-center justify-start select-none ${
        selectedCurrencyId === c.currency
          ? "bg-slate-100 text-gray-900 rounded-md shadow-lg"
          : ""
      }`}
      onClick={() => {
        handleCurrency(c.currency);
        toggleCurrencyList();
      }}
    >
      <div className="w-5 h-5">
        <img
          className="w-full h-full"
          src={`./currency/${CURRENCY[c.currency]}.svg`}
          alt={CURRENCY[c.currency]}
        />
      </div>
      <div className="m-0.5"></div>
      {CURRENCY[c.currency].toUpperCase()}
    </button>
  ));

  return (
    <>
      {activeBet ? (
        address ? (
          chain === CHAIN.MAINNET ? (
            <>
              <div
                className="fixed top-0 h-full z-10 w-full bg-gray-900/25"
                onClick={isLoading ? undefined : () => closeBet(false)}
              ></div>
              {isLoading ? (
                <TransactionPending />
              ) : (
                <section
                  className={`z-30 bg-white w-full border-t fixed p-4 rounded-t-lg -bottom-full duration-300 ${
                    activeBet ? "bottom-0" : ""
                  }`}
                >
                  <>
                    <div className="flex items-center justify-between text-black ">
                      <div className="flex items-end ">
                        <div className="text-xl font-medium mr-4 leading-none">
                          Bet
                        </div>
                        <div className="text-sm text-betDataColor leading-tight">
                          {IsEventStart !== undefined &&
                          IsEventStart !== EventStatus.notStarted
                            ? "Odds fixed "
                            : `Odds will be fixed at ${moment(
                                event?.startDate
                              ).format("YYYY-MM-DD HH:mm")} `}
                        </div>
                      </div>
                      <div onClick={() => closeBet(false)}>
                        <svg
                          className="h-6 w-6 cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="border-t border-lineColor my-4"></div>
                    <div className="flex flex-col">
                      <div className="mb-2">
                        <div className="flex justify-between items-center">
                          <div>
                            <div className="text-sm text-customGray">
                              {event?.name}
                            </div>
                            <div className="flex items-end">
                              <div className="text-lg font-semibold text-black leading-tight">
                                {
                                  event?.poolInfo.find(
                                    (pool) => pool.id === preBet.poolId
                                  )?.name
                                }
                              </div>
                            </div>
                            {event?.poolInfo ? (
                              <div className="flex items-center">
                                {event?.poolInfo[0] ? (
                                  <div className="text-sm text-gray-400 flex-shrink-0">
                                    {event?.poolInfo[0].teamName}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="mx-2 text-gray-400">-</div>
                                {event?.poolInfo[1] ? (
                                  <div className="text-sm text-gray-400 flex-shrink-0">
                                    {event?.poolInfo[1].teamName}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="text-xl font-bold text-black whitespace-nowrap">
                            {preBet.coefficient}
                          </div>
                        </div>
                      </div>
                      <BetInput
                        value={value}
                        handleChangeInput={handleChangeInput}
                        isLowBalance={isLowBalance}
                        isNotMinBet={isNotMinBet}
                        isNotMaxBet={isNotMaxBet}
                        isValid={isValid}
                        isValidNumber={isValidNumber}
                        IsEventStart={IsEventStart}
                        EventStatus={EventStatus}
                        MAX_BET={MAX_BET}
                        MIN_BET={MIN_BET}
                        selectedCurrencyId={selectedCurrencyId}
                        CURRENCY={CURRENCY}
                        toggleCurrencyList={toggleCurrencyList}
                        isCurrencyListVisible={isCurrencyListVisible}
                        renderedCurrencies={renderedCurrencies}
                        balance={balance}
                        currencyName={currencyName}
                        isMobile={isMobile}
                        setShowKeyboard={setShowKeyboard}
                      />
                      <div className="flex justify-between items-center font-medium text-sm mb-4">
                        <span className="text-betDataColor">
                          Potential winnings:
                        </span>
                        <span className="text-greenWinColor font-bold whitespace-nowrap">
                          {calculatePotentialWinnings()} {currencyName}
                        </span>
                      </div>
                      <QuickBets
                        quickBets={quickBets}
                        handleFastBet={handleFastBet}
                        currencyName={currencyName}
                      />
                      {showKeyboard && isMobile && (
                        <>
                          <KeyboardComponent
                            layout={keyboardOptions.layout}
                            display={keyboardOptions.display}
                            onChange={handleChangeKeyboard}
                            keyboardRef={(r: any) => (keyboard.current = r)}
                          />
                        </>
                      )}
                      {isVideoPlaying && (
                        <div className="absolute bottom-2 w-full h-full z-20 flex justify-center items-center">
                          <PlaceBetAnimation />
                        </div>
                      )}

                      {/* <div className="absolute bottom-2 ">
                        <video
                          ref={videoRef}
                          muted
                          autoPlay
                          loop
                          style={{ backgroundColor: "transparent" }}
                        >
                          <source src={"./Confetti.webm"} type="video/webm" />
                          Your browser does not support the video tag.
                        </video>
                      </div> */}
                      <button
                        disabled={
                          !value ||
                          isLowBalance ||
                          isNotMinBet ||
                          isNotMaxBet ||
                          !isValid ||
                          !isValidNumber ||
                          (IsEventStart !== undefined &&
                            IsEventStart !== EventStatus.notStarted)
                        }
                        className="mb-2 flex items-center"
                        onClick={handlePlaceBet}
                      >
                        <div className="w-full h-full justify-start items-start inline-flex">
                          <div
                            className={`grow shrink basis-0 h-12 px-6 ${
                              !value ||
                              isLowBalance ||
                              isNotMinBet ||
                              isNotMaxBet ||
                              !isValid ||
                              !isValidNumber ||
                              (IsEventStart !== undefined &&
                                IsEventStart !== EventStatus.notStarted)
                                ? "bg-gray-200"
                                : "bg-blue-600 cursor-pointer"
                            } rounded-xl justify-center items-center flex`}
                          >
                            <div className="w-1.5 h-5 relative" />
                            <div
                              className={`text-center  ${
                                !value ||
                                isLowBalance ||
                                isNotMinBet ||
                                isNotMaxBet ||
                                !isValid ||
                                !isValidNumber ||
                                (IsEventStart !== undefined &&
                                  IsEventStart !== EventStatus.notStarted)
                                  ? "text-gray-400"
                                  : "text-white"
                              } text-md font-medium  leading-snug select-none`}
                            >
                              Place bet
                            </div>
                            <div className="w-1 h-5 relative" />
                          </div>
                        </div>
                      </button>
                    </div>
                  </>
                </section>
              )}
            </>
          ) : (
            ""
          )
        ) : (
          <ConnectWalletPage />
        )
      ) : (
        ""
      )}
    </>
  );
};

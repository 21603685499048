import React from "react";
import ErrorMessage from "../../../../components/ErrorMessage";
import LoadingSpinner from "../../../../components/LoaderSpinner";
import NoActivityPage from "../../../../components/NoActivityPage";
import ScrollHandler from "../../../../components/ScrollHandler";
import ScrollToTopButton from "../../../../components/ScrollToTopButton";
import { BetItem } from "./components";

export const BetsList = ({
  bets,
  betsStatus,
  error,
  showScrollButton,
  handleScroll,
  scrollToTop,
}: {
  bets: any[];
  betsStatus: string;
  error: string | null;
  showScrollButton: boolean;
  handleScroll: () => void;
  scrollToTop: () => void;
}) => {
  const renderedBets = bets.map((bet, i) => <BetItem key={i} bet={bet} />);

  let content =
    betsStatus === "failed" ? (
      <ErrorMessage error={error} />
    ) : betsStatus === "loading" && renderedBets.length < 1 ? (
      <LoadingSpinner />
    ) : renderedBets.length ? (
      <>
        <ScrollHandler onScroll={handleScroll} />
        <ScrollToTopButton
          showScrollButton={showScrollButton}
          onClick={scrollToTop}
        />
        {renderedBets}
        {betsStatus === "loading" ? <LoadingSpinner /> : null}
      </>
    ) : (
      <NoActivityPage text={"No activities yet"} />
    );

  return <section className="px-4 py-4">{content}</section>;
};

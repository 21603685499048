import { PendingDots } from "./components";

export const TransactionPending = () => {
  return (
    <div className="top-1/3 z-20 w-full fixed  duration-300">
      <div className="flex items-center justify-center">
        <div className="flex flex-col bg-white rounded-2xl  px-5 pt-6 pb-7.5">
          <div className="flex items-center justify-center mr-7.5">
            <svg
              width="84"
              height="50"
              viewBox="0 0 84 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 12L34 12"
                stroke="#B0E3FF"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M14 36L34 36"
                stroke="#B0E3FF"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M1 26L26 26"
                stroke="#B0E3FF"
                stroke-width="2"
                stroke-linecap="round"
              />
              <rect
                x="44.8579"
                y="0.505096"
                width="40"
                height="40"
                rx="20"
                transform="rotate(15 44.8579 0.505096)"
                fill="#0098EA"
              />
              <path
                d="M67.9277 18.0746L54.7348 14.5396C52.309 13.8896 50.0704 16.0943 50.724 18.5366L55.0847 34.831C55.3691 35.8949 56.7007 36.2517 57.4789 35.4725L69.4043 23.542C71.1888 21.7566 70.3534 18.7246 67.9293 18.0751L67.9277 18.0746ZM56.2121 30.5971L55.3584 26.6901L53.1302 17.8912C52.9792 17.3258 53.4961 16.7916 54.0871 16.95L59.4799 18.3949L56.21 30.5983L56.2121 30.5971ZM67.6386 21.7769L61.3102 28.2866L58.6179 31.2417L61.8878 19.0384L67.2805 20.4833C67.8716 20.6417 68.0521 21.3627 67.6386 21.7769Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="border-t border-transactionsLineColor mb-4 -mx-1"></div>
          <div className="flex flex-col">
            <div className="flex leading-none justify-center mb-2">
              Do not close page until transaction complete
            </div>
            <div className="flex justify-center">
              <div className="leading-none">Transaction pending</div>
              <PendingDots />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

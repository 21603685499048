import { Currency } from "../app/features/currency/currencySlice";
import { Transaction } from "../app/features/transactions/transactionsSlice";
import { BACKEND_KEYS } from "../common/consts";

import WebSocketClient from "./WebSocketClient";

const data = { agent: "transactions", lang: "en", auth: { type: "webSite" } };

class TransactionApi {
  private webSocketClient: WebSocketClient;

  constructor() {
    this.webSocketClient = WebSocketClient.getInstance(BACKEND_KEYS.SERVER_URL);
  }

  async getBalance(wallet: string): Promise<any> {
    return this.webSocketClient.request<Currency[]>({
      method: "balance_getBalance",
      params: {
        wallet,
      },
      ...data,
    });
  }

  async getAllTransactionsByWallet(
    page: number,
    perPage: number,
    wallet: string
  ) {
    return this.webSocketClient.request<Transaction>({
      method: "transactions_getAllTransactionsByWallet",
      params: {
        page,
        perPage,
        wallet: String(wallet),
      },
      ...data,
    });
  }

  // Dont use yet
  // async getTransactionById(id: string) {
  //   return this.webSocketClient.request<Transaction>({
  //     method: "eventEndpoints_getById",
  //     params: {
  //       id,
  //     },
  //   });
  // }
}

const transactionApi = new TransactionApi();
export default transactionApi;

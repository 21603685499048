import React, { useEffect, useState } from "react";
import {
  CHAIN,
  TonConnectButton,
  useTonAddress,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import useTonBalance from "../../../hooks/useTonBalance";
import { useSessionStorage } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";
import {
  selectAllCurrency,
  fetchBalance,
} from "../../../app/features/currency/currencySlice";
import { setCurrentPage } from "../../../app/features/currentPage/currentPagesSlice";
import {
  getPostsStatus,
  loginUser,
  setUserStatus,
} from "../../../app/features/user/userSlice";
import LoadingSpinner from "../../../components/LoaderSpinner";
import ScrollHandler from "../../../components/ScrollHandler";
import { ChangeNetworkMessage } from "../../../containers/ChangeNetwork";
import { useTelegram } from "../../../hooks/useTelegram";

import * as amplitude from "@amplitude/analytics-browser";

export const Header = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user, isPremium, refAddress } = useTelegram();
  const userStatus = useSelector(getPostsStatus);
  const [tonConnectUI] = useTonConnectUI();
  const currencies = useSelector(selectAllCurrency);
  const [activeDiv, setActiveDiv] = useSessionStorage("page", "/");
  const [pointAmount, setPointAmount] = useState(0);
  const handleSvgClick = (divLink: string) => {
    setActiveDiv(divLink);
    dispatch(setCurrentPage(divLink));
  };
  const [showShadow, setshowShadow] = useState(false);
  const { currentPage } = useSelector((state: RootState) => state.currentPage);
  const address = useTonAddress();
  useTonBalance(address);

  const { tonBalance } = useSelector((state: RootState) => state.wallet);

  const onDisconnect = async () => {
    await tonConnectUI.disconnect();
  };

  useEffect(() => {
    if (address && address.length) {
      dispatch(fetchBalance(address));
    }
  }, [address]);

  useEffect(() => {
    if (currencies.length) {
      setPointAmount(currencies[0].amount);
    }
  }, [currencies]);

  useEffect(() => {
    if (userStatus === "idle" && address && user) {
      console.log("isPremium", isPremium);
      dispatch(
        loginUser({
          wallet: address,
          clientId: String(user.id),
          referrer: refAddress,
          isPremium: isPremium ? isPremium : false,
        })
      );
      if (refAddress) {
        // amplitude.track("Referral", {
        //   referrer: refAddress,
        //   clientId: String(user.id),
        //   wallet: address,
        // });
      }
      dispatch(setUserStatus("idle"));
    }
  }, [address]);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    currentScrollY > 0 ? setshowShadow(true) : setshowShadow(false);
  };

  return (
    <header
      className={`flex items-center justify-center w-full flex-col px-4 py-2 !fixed top-0 bg-white z-10 ${
        showShadow ? `drop-shadow-md` : ""
      }`}
    >
      <ScrollHandler onScroll={handleScroll} />
      {address ? (
        tonConnectUI.account?.chain === CHAIN.MAINNET ? (
          <>
            {userStatus === "loading" ? (
              <LoadingSpinner />
            ) : (
              <>
                <Link
                  to="/mywallet"
                  className="flex gap-x-12 bg-gray-100 w-full py-3 pl-2 pr-3.5 h-headerHeight rounded-lg justify-between "
                  onClick={() => handleSvgClick("/mywallet")}
                >
                  <div className="relative flex items-center">
                    <svg
                      width="32"
                      height="26"
                      viewBox="0 0 32 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.60001 10.075C5.60001 8.25483 5.60001 7.34474 5.94879 6.64953C6.25558 6.038 6.74512 5.54082 7.34724 5.22923C8.03175 4.875 8.92784 4.875 10.72 4.875H22.08C23.8722 4.875 24.7683 4.875 25.4528 5.22923C26.0549 5.54082 26.5444 6.038 26.8512 6.64953C27.1184 7.18201 27.1809 7.84054 27.1955 8.9375H21.6C20.109 8.9375 19.3635 8.9375 18.7754 9.18489C17.9913 9.51475 17.3684 10.1474 17.0436 10.9438C16.8 11.541 16.8 12.2982 16.8 13.8125C16.8 15.3268 16.8 16.084 17.0436 16.6812C17.3684 17.4776 17.9913 18.1103 18.7754 18.4401C19.3635 18.6875 20.109 18.6875 21.6 18.6875H27.1955C27.1809 19.7845 27.1184 20.443 26.8512 20.9755C26.5444 21.587 26.0549 22.0842 25.4528 22.3958C24.7683 22.75 23.8722 22.75 22.08 22.75H10.72C8.92784 22.75 8.03175 22.75 7.34724 22.3958C6.74512 22.0842 6.25558 21.587 5.94879 20.9755C5.60001 20.2803 5.60001 19.3702 5.60001 17.55V10.075Z"
                        fill="#007AFF"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.2 13.6094C18.2 12.4718 18.2 11.903 18.418 11.4685C18.6097 11.0863 18.9157 10.7755 19.292 10.5808C19.7198 10.3594 20.2799 10.3594 21.4 10.3594H25C26.1201 10.3594 26.6802 10.3594 27.108 10.5808C27.4843 10.7755 27.7903 11.0863 27.982 11.4685C28.2 11.903 28.2 12.4718 28.2 13.6094V14.0156C28.2 15.1532 28.2 15.722 27.982 16.1565C27.7903 16.5387 27.4843 16.8495 27.108 17.0442C26.6802 17.2656 26.1201 17.2656 25 17.2656H21.4C20.2799 17.2656 19.7198 17.2656 19.292 17.0442C18.9157 16.8495 18.6097 16.5387 18.418 16.1565C18.2 15.722 18.2 15.1532 18.2 14.0156V13.6094ZM23.2 13.8125C23.2 14.71 22.4837 15.4375 21.6 15.4375C20.7164 15.4375 20 14.71 20 13.8125C20 12.915 20.7164 12.1875 21.6 12.1875C22.4837 12.1875 23.2 12.915 23.2 13.8125Z"
                        fill="#007AFF"
                      />
                    </svg>
                    <button
                      type="button"
                      className="flex items-center gap-x-1 text-xs font-semibold leading-6 text-customBlue"
                      aria-expanded="false"
                    >
                      My wallet
                    </button>
                  </div>

                  <div className="relative flex items-center">
                    {currentPage === "/mywallet" ? (
                      <>
                        <svg
                          width="26"
                          height="26"
                          viewBox="0 0 26 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.0252 2.60975C9.25925 3.289 9.25925 4.53158 9.25925 7.01566V18.9843C9.25925 21.4684 9.25925 22.711 10.0252 23.3902C10.7911 24.0695 11.9622 23.8658 14.3043 23.4574L16.8285 23.0176C19.422 22.5647 20.7187 22.3383 21.489 21.3872C22.2592 20.4349 22.2592 19.0591 22.2592 16.3063V9.69366C22.2592 6.942 22.2592 5.56616 21.4901 4.61391C20.7187 3.66275 19.4209 3.43633 16.8274 2.98458L14.3054 2.54366C11.9632 2.13525 10.7922 1.93158 10.0262 2.61083M12.5092 11.0164C12.9577 11.0164 13.3217 11.3967 13.3217 11.8657V14.1342C13.3217 14.6033 12.9577 14.9836 12.5092 14.9836C12.0607 14.9836 11.6967 14.6033 11.6967 14.1342V11.8657C11.6967 11.3967 12.0607 11.0164 12.5092 11.0164Z"
                            fill="#E7393F"
                          />
                          <path
                            d="M8.17592 4.875C5.94642 4.87825 4.784 4.927 4.043 5.668C3.25 6.461 3.25 7.73717 3.25 10.2917V15.7083C3.25 18.2618 3.25 19.5379 4.043 20.332C4.784 21.0719 5.94642 21.1217 8.17592 21.125C8.125 20.449 8.125 19.669 8.125 18.8251V7.17492C8.125 6.32992 8.125 5.54992 8.17592 4.875Z"
                            fill="#E7393F"
                          />
                        </svg>
                        <button
                          type="button"
                          className="flex items-center gap-x-1 text-xs font-semibold leading-6 text-disconnectColor"
                          aria-expanded="false"
                          onClick={onDisconnect}
                        >
                          Disconnect
                        </button>
                      </>
                    ) : (
                      <>
                        <div className="pr-1.5 w-8 h-8">
                          <img
                            className="w-full h-full bg-slate-100"
                            src={`./currency/betcoin.svg`}
                            alt={"points"}
                          />
                        </div>

                        <a className="text-xs font-semibold leading-6 text-black">
                          {/* {tonBalance ? tonBalance : 0} */}
                          {pointAmount}
                        </a>
                      </>
                    )}
                  </div>
                </Link>
              </>
            )}
          </>
        ) : (
          <ChangeNetworkMessage />
        )
      ) : (
        <TonConnectButton />
      )}
    </header>
  );
};

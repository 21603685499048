export const BACKEND_KEYS = {
  DEV_URL: "http://localhost:3000/",
  SERVER_URL: "wss://api.betton.app",
  POOL_URL: "pool",
  USER_URL: "user",
  EVENT_URL: "event",
  TRANSACTION_URL: "transactions",
  BETS_URL: "bets",
  FAVORITES_URL: "favorites",
  TONSCAN_URL: "https://tonscan.com/",
};

import { useState } from "react";

import Switch from "react-switch";

export const Setting = ({
  name,
  checked,
  onChange,
}: {
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="text-black text-base font-bold font-['SF Pro Text'] leading-none tracking-tight">
        {name}
      </div>
      <Switch
        checked={checked}
        onChange={onChange}
        onColor="#007BFF" // Колір при увімкненому стані
        offColor="#ccc" // Колір при вимкненому стані
        onHandleColor="#fff" // Колір перемикача при увімкненому стані
        offHandleColor="#fff" // Колір перемикача при вимкненому стані
        handleDiameter={20} // Діаметр перемикача
        uncheckedIcon={false} // Іконка при вимкненому стані
        checkedIcon={false} // Іконка при увімкненому стані
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" // Тінь перемикача
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={24} // Висота світчера
        width={48} // Ширина світчера
        className="react-switch"
      />
    </div>
  );
};

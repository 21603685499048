import React from "react";

export const BetOutcome = ({ win }: { win: boolean | undefined }) => {
  if (typeof win === "undefined") return null;

  return (
    <div
      className={`min-w-[30px] h-4 flex items-center ${
        win ? "bg-emerald-500 bg-opacity-20" : "bg-[#ab1e00] bg-opacity-20"
      } rounded-lg`}
    >
      <div
        className={`text-center w-full flex justify-center ${
          win ? "text-emerald-500" : "text-[#df4449]"
        } text-[8px] font-medium font-['SF Pro Text'] leading-[8px]`}
      >
        {win ? "WON" : "LOSS"}
      </div>
    </div>
  );
};


import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllCategories,
  getCategoriesStatus,
  getCategoriesError,
  fetchCategories,
  setCategory,
} from "../../app/features/categories/categoriesSlice";
import { AppDispatch, RootState } from "../../app/store";

const useCategoriesData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const categories = useSelector(selectAllCategories);
  const categoriesStatus = useSelector(getCategoriesStatus);
  const error = useSelector(getCategoriesError);
  const { selectedTypesId } = useSelector((state: RootState) => state.types);

  useEffect(() => {
    if (selectedTypesId !== null) dispatch(fetchCategories(selectedTypesId));
    dispatch(setCategory(null));
  }, [selectedTypesId, dispatch]);

  const handleCategoryClick = (categoryId: number) => {
    dispatch(setCategory(categoryId));
  };

  return { categories, categoriesStatus, error, handleCategoryClick };
};

export default useCategoriesData;

import { SettingsList } from "../../containers";

export const SettingsPage = () => {
  return (
    <>
      <div className="p-4">
        <SettingsList />
      </div>
    </>
  );
};

import { Bet } from "../app/features/bet/betSlice";
import { BACKEND_KEYS } from "../common/consts";

import WebSocketClient from "./WebSocketClient";

const data = { agent: "event", lang: "en", auth: { type: "webSite" } };

class BetsApi {
  private webSocketClient: WebSocketClient;

  constructor() {
    this.webSocketClient = WebSocketClient.getInstance(BACKEND_KEYS.SERVER_URL);
  }

  async getAllBets(
    page: number,
    perPage: number,
    clientId: string,
    status: number
  ) {
    return this.webSocketClient.request<Bet>({
      method: "eventEndpoints_getAllByClientId",
      params: {
        page,
        perPage,
        clientId: String(clientId),
        status,
      },
      ...data,
    });
  }

  // Dont use yet
  async getBetById(betId: number) {
    return this.webSocketClient.request<Bet>({
      method: "eventEndpoints_getById",
      params: {
        betId,
      },
      ...data,
    });
  }
}

const betsApi = new BetsApi();
export default betsApi;

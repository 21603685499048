import React from "react";
import useBetTypesData from "./useBetTypesData";
import { BetTypesList } from "./components";

export const BetTypes = () => {
  const {
    betTypes,
    betTypesStatus,
    error,
    selectedBetTypesId,
    handleTypeClick,
  } = useBetTypesData();

  return (
    <BetTypesList
      betTypes={betTypes}
      betTypesStatus={betTypesStatus}
      error={error}
      selectedBetTypesId={selectedBetTypesId}
      handleTypeClick={handleTypeClick}
    />
  );
};

import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTelegram } from "../../../../hooks/useTelegram";
import { useTonAddress } from "@tonconnect/ui-react";
export const CopyButton = () => {
  const { userId } = useTelegram();
  const [copied, setCopied] = useState(false);
  const address = useTonAddress();
  const handleCopyClick = async () => {
    if (!address) return;
    const urlToCopy = `https://t.me/BET_TON_APP_BOT/get_free_bets?startapp=${address}`;
    try {
      await navigator.clipboard.writeText(urlToCopy);
      setCopied(true);
      toast.success("Invite link copied to clipboard!");
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
      toast.error("Failed to copy URL.");
    }
  };

  return (
    <>
      <div
        className="min-w-[50px] h-[50px] bg-blue-600 rounded-xl justify-center items-center inline-flex cursor-pointer"
        onClick={handleCopyClick}
      >
        {copied ? (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm5.707 7.293l-6 6a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L9 11.586l5.293-5.293a1 1 0 111.414 1.414z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.96 6.07999H2.15996C1.07902 6.07999 0.199951 6.95905 0.199951 8.04V17.84C0.199951 18.921 1.07902 19.8001 2.15996 19.8001H11.96C13.041 19.8001 13.92 18.921 13.92 17.84V8.04C13.92 6.95905 13.041 6.07999 11.96 6.07999Z"
              fill="white"
            />
            <path
              d="M17.8401 0.199921H8.04009C7.52026 0.199921 7.02173 0.406421 6.65415 0.773994C6.28658 1.14157 6.08008 1.6401 6.08008 2.15993V4.11994H13.9201C14.4399 4.11994 14.9385 4.32644 15.3061 4.69401C15.6736 5.06159 15.8801 5.56012 15.8801 6.07995V13.92H17.8401C18.36 13.92 18.8585 13.7135 19.2261 13.3459C19.5936 12.9783 19.8001 12.4798 19.8001 11.96V2.15993C19.8001 1.6401 19.5936 1.14157 19.2261 0.773994C18.8585 0.406421 18.36 0.199921 17.8401 0.199921Z"
              fill="white"
            />
          </svg>
        )}
      </div>

      <ToastContainer className={"fixed top-[70px] z-40"} />
    </>
  );
};

import { BACKEND_KEYS } from "../common/consts";

import WebSocketClient from "./WebSocketClient";

const data = { agent: "transactions", lang: "en", auth: { type: "webSite" } };

class BonusesApi {
  private webSocketClient: WebSocketClient;

  constructor() {
    this.webSocketClient = WebSocketClient.getInstance(BACKEND_KEYS.SERVER_URL);
  }

  async getNotClaimedRewardSum(wallet: string) {
    return this.webSocketClient.request<any>({
      method: "referralReward_getNotClaimedRewardSum",
      params: {
        wallet,
      },
      ...data,
    });
  }

  async claimAllSum(wallet: string) {
    return this.webSocketClient.request<any>({
      method: "referralReward_claimAllSum",
      params: {
        wallet,
      },
      ...data,
    });
  }
}

const bonusesApi = new BonusesApi();
export default bonusesApi;

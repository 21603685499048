import { useSelector } from "react-redux";

import {
  CURRENCY,
  Currency,
} from "../../../../../../app/features/currency/currencySlice";
import { RootState } from "../../../../../../app/store";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import InfoTooltip from "../../../../../../components/InfoTooltip";

export const CurrencyItem = ({ c }: { c: Currency }) => {
  const { tonBalance } = useSelector((state: RootState) => state.wallet);
  return (
    <div className="relative flex items-center justify-center flex-col bg-slate-100 border rounded-md p-2 border-eventBorder">
      {c.currency === CURRENCY.bbetcoin && (
        <div className="absolute top-0 right-0 m-2 z-10">
          <InfoTooltip
            tooltipText={`Get bonus $BETCOIN for referrals and taps`}
          />
        </div>
      )}

      <div className="flex items-center justify-center gap-x-1 relative pr-6">
        <div className="rounded-full border-2">
          <div className="w-8 h-8 border-white border-2 rounded-full">
            <img
              className="w-full h-full"
              src={`./currency/${CURRENCY[c.currency]}.svg`}
              alt={CURRENCY[c.currency]}
            />
          </div>
        </div>
        <span className="whitespace-pre-wrap">
          {c.currency === CURRENCY.bbetcoin
            ? "Bonus\nBetcoin".toUpperCase()
            : CURRENCY[c.currency].toUpperCase().replace(" ", "\n")}
        </span>
      </div>

      <div className="flex justify-center w-full">
        <span className="text-lg font-semibold leading-5 text-black ">
          {c.currency === CURRENCY.ton ? tonBalance ?? 0 : c.amount}
        </span>
      </div>
    </div>
  );
};

const NoActivityPage = ({ text }: { text: string | null }) => {
  return (
    <div className="flex flex-col justify-center items-center mt-20">
      <svg
        width="105"
        height="104"
        viewBox="0 0 105 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M69.8333 17.342C79.2583 17.394 84.363 17.8143 87.691 21.1423C91.5 24.9513 91.5 31.0787 91.5 43.3333V69.3333C91.5 81.5923 91.5 87.7197 87.691 91.5287C83.8863 95.3333 77.7547 95.3333 65.5 95.3333H39.5C27.2453 95.3333 21.1137 95.3333 17.309 91.5287C13.5 87.7153 13.5 81.5923 13.5 69.3333V43.3333C13.5 31.0787 13.5 24.9513 17.309 21.1423C20.637 17.8143 25.7417 17.394 35.1667 17.342"
          stroke="#D7E6F0"
          stroke-width="3"
        />
        <path
          d="M45.9999 60.6667H74.1666M30.8333 60.6667H32.9999M30.8333 45.5H32.9999M30.8333 75.8333H32.9999M45.9999 45.5H74.1666M45.9999 75.8333H74.1666"
          stroke="#D7E6F0"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M35.1667 15.1667C35.1667 13.4428 35.8516 11.7895 37.0706 10.5705C38.2895 9.35149 39.9428 8.66667 41.6667 8.66667H63.3334C65.0573 8.66667 66.7106 9.35149 67.9296 10.5705C69.1486 11.7895 69.8334 13.4428 69.8334 15.1667V19.5C69.8334 21.2239 69.1486 22.8772 67.9296 24.0962C66.7106 25.3152 65.0573 26 63.3334 26H41.6667C39.9428 26 38.2895 25.3152 37.0706 24.0962C35.8516 22.8772 35.1667 21.2239 35.1667 19.5V15.1667Z"
          stroke="#D7E6F0"
          stroke-width="3"
        />
      </svg>
      <p className="flex justify-center w-full text-lg font-medium text-customGray pt-4 leading-none">
        {text}
      </p>
    </div>
  );
};

export default NoActivityPage;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import transactionApi from "../../../services/transactionApi";

export enum CURRENCY {
  ton,
  betcoin,
  points,
  bbetcoin,
}

let Currency = [
  {
    currency: 0,
    amount: 0,
  },
  {
    currency: 1,
    amount: 0,
  },
  {
    currency: 2,
    amount: 0,
  },
  {
    currency: 3,
    amount: 0,
  },
];

export interface Currency {
  currency: number;
  amount: number;
}

interface CurrencyState {
  currency: Currency[];
  selectedCurrencyId: number;
  status: string;
  error: string | null;
}
const initialState: CurrencyState = {
  currency: [],
  selectedCurrencyId: 1,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchBalance = createAsyncThunk(
  "currency/fetchBalance",
  async (wallet: string) => {
    const data = await transactionApi.getBalance(wallet);
    return data;
  }
);

const CurrencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.selectedCurrencyId = action.payload;
    },
    decrementBalance: (
      state,
      action: PayloadAction<{ currencyId: number; amount: number }>
    ) => {
      const currency = state.currency.find(
        (curr) => curr.currency === action.payload.currencyId
      );
      if (currency) {
        currency.amount -= action.payload.amount;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBalance.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.currency = action.payload.filter(
          (currency: Currency) =>
            currency.currency !== CURRENCY.points &&
            currency.currency !== CURRENCY.ton
        );
      })
      .addCase(fetchBalance.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "An error occurred";
        // state.status = "succeeded";
      });
  },
});

export const { setCurrency, decrementBalance } = CurrencySlice.actions;

export const selectAllCurrency = (state: { currency: CurrencyState }) =>
  state.currency.currency;

export const getCurrencyStatus = (state: { currency: CurrencyState }) =>
  state.currency.status;

export const getCurrencyError = (state: { currency: CurrencyState }) =>
  state.currency.error;

export const selectCurrencyById = (
  state: { currency: CurrencyState },
  currencyId: number
) =>
  state.currency.currency.find((currency) => currency.currency === currencyId);

export default CurrencySlice.reducer;

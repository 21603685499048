import { useTelegram } from "../../hooks/useTelegram";
import { CopyButton, InviteButton } from "./components";

export const InvitesButtons = () => {
  return (
    <div className="w-full flex justify-around fixed bottom-[70px] px-4 ">
      <InviteButton />
      <CopyButton />
    </div>
  );
};

import { useEffect } from "react";

const tg = window.Telegram.WebApp;

export function useTelegram() {
  useEffect(() => {
    tg.ready();

    tg.expand();

    tg.disableVerticalSwipes();

    tg.enableClosingConfirmation();
  }, []);

  return {
    tg,
    user: tg.initDataUnsafe?.user,
    userId: tg.initDataUnsafe?.user?.id,
    isPremium: tg.initDataUnsafe?.is_premium,
    refAddress: tg.initDataUnsafe?.start_param,
  };
}

import React from "react";
import useCategoriesData from "./useCategoriesDataю";
import { CategoriesList } from "./components";

export const Categories = () => {
  const { categories, categoriesStatus, error, handleCategoryClick } =
    useCategoriesData();

  return (
    <CategoriesList
      categories={categories}
      categoriesStatus={categoriesStatus}
      error={error}
      handleCategoryClick={handleCategoryClick}
    />
  );
};

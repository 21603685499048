import { SendTransactionRequest, useTonConnectUI } from "@tonconnect/ui-react";

import TonWeb from "tonweb";

export const useSendBetTransaction = () => {
  const [tonConnectUI] = useTonConnectUI();

  const toNano = (value: number): string => {
    return `${value * 1000000000}`;
  };

  const sendTransaction = async (args: {
    to: string;
    value: number;
    payload: string;
  }) => {
    const forwardPayload = new TonWeb.boc.Cell();

    forwardPayload.bits.writeUint(0, 32);
    forwardPayload.bits.writeString(args.payload);

    let payload = TonWeb.utils.bytesToBase64(await forwardPayload.toBoc());

    try {
      const defaultTx: SendTransactionRequest = {
        messages: [
          {
            address: args.to,
            amount: toNano(args.value),
            payload: payload,
          },
        ],
        network: tonConnectUI.account?.chain,
        validUntil: Date.now() + 1 * 60 * 1000,
      };

      const transactionHash = await tonConnectUI.sendTransaction(defaultTx);
      return transactionHash;
    } catch (error) {
      console.error("Error sending transaction:", error);
    }
  };
  return { sendTransaction };
};

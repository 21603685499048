import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import eventsApi from "../../../services/eventsApi";


export interface Category {
  id: number;
  name: string;
  icon: string;
  typeId: number;
}
interface CategoriesState {
  categories: Category[];
  selectedCategoryId: number | null;
  status: string;
  error: string | null;
}
const initialState: CategoriesState = {
  categories: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  selectedCategoryId: null,
};

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (typeId?: number) => {
    const response = await eventsApi.getAllCategories(typeId);
    return response;
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategoryStatus(state, action) {
      state.status = action.payload;
    },
    setCategory: (state, action) => {
      state.selectedCategoryId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCategories.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "An error occurred";
        // state.status = "succeeded";
      });
  },
});

export const { setCategory, setCategoryStatus } = categoriesSlice.actions;

export const selectAllCategories = (state: { categories: CategoriesState }) =>
  state.categories.categories;

export const getCategoriesStatus = (state: { categories: CategoriesState }) =>
  state.categories.status;

export const getCategoriesError = (state: { categories: CategoriesState }) =>
  state.categories.error;

export const selectCategoryById = (
  state: { categories: CategoriesState },
  categoryId: number
) => state.categories.categories.find((category) => category.id === categoryId);

export default categoriesSlice.reducer;

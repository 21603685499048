import React from "react";

export const QuickBets = ({ quickBets, handleFastBet, currencyName }: any) => {
  return (
    <div className="mb-2">
      <div className="flex justify-between items-center">
        <div>
          <div className="text-sm font-medium text-betDataColor">
            Quick bets
          </div>
          <div className="text-sm text-gray-400">
            Select a stake amount to place a bet
          </div>
        </div>
      </div>
      <div className="w-full h-auto mt-2 mb-2 flex flex-row gap-4 justify-between ">
        {quickBets.map((bet: number, i: number) => (
          <div
            key={i}
            className={`h-10 bg-quickBetBg text-white rounded-md flex justify-center items-center px-2 py-1 w-full cursor-pointer select-none`}
            onClick={() => handleFastBet(bet)}
          >
            <span className="text-base font-medium text-white">
              {bet} {currencyName}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

import React from "react";
import { Timer } from "./components";
import useEligibility from "./useEligibility";
import { ClickAnimation } from "../../components/animation/ClickAnimation";

export const ClaimButton = () => {
  const {
    isDisabled,
    expiryTimestamp,
    seconds,
    minutes,
    hours,
    isRunning,
    handleTap,
    handleClaim,
    counter,
    tapGoal,
    handleTouchStart,
    handleButtonClick,
    isClicked,
    clicks,
    animDir,
    isClaiming,
    claimError,
  } = useEligibility();

  const buttonText = counter >= tapGoal ? "Claim" : "Tap";

  const progress = (counter / tapGoal) * 100;

  return (
    <div className="flex flex-col items-center justify-center gap-y-4 mb-5 px-2">
      {!claimError && (
        <div className="mt-4 p-4 bg-blue-100 border border-blue-300 rounded-lg shadow-lg text-gray-700 text-center ">
          {isRunning ? (
            <Timer hours={hours} minutes={minutes} seconds={seconds} />
          ) : (
            <div className="relative w-full h-5 bg-slate-600 rounded-full shadow-inner overflow-hidden border border-black">
              <div
                className="absolute h-full bg-blue-600 transition-width duration-500"
                style={{ width: `${progress}%` }}
              ></div>
              <p className="absolute inset-0 flex items-center justify-center text-white text-lg font-extrabold font-['SF Pro Text'] leading-[18px] tracking-tight">
                {counter}/{tapGoal}
              </p>
            </div>
          )}

          <div className="flex justify-center items-center mt-4">
            <button
              onTouchStart={handleTouchStart}
              onClick={(e) => handleButtonClick(e)}
              className={`${isClicked ? `` : ""} select-none`}
              disabled={isDisabled || isClaiming}
            >
              <div className="relative w-72 h-56">
                {(isClaiming || isRunning) && (
                  <div className="absolute w-48 h-48 inset-0 m-auto bg-black opacity-60 rounded-full z-10"></div>
                )}
                <ClickAnimation triggerHit={isClicked} />
                {clicks.length > 0 &&
                  clicks.map(
                    (click) =>
                      click.id &&
                      click.x !== undefined &&
                      click.y !== undefined && (
                        <span
                          key={click.id}
                          className="absolute text-[#c33eeb] text-3xl animate-fadeOutUp"
                          style={{
                            left: `${click.x}px`,
                            top: `${click.y}px`,
                          }}
                        >
                          +1
                        </span>
                      )
                  )}
              </div>
            </button>
          </div>

          <p className="text-xl font-semibold mb-2">Tap to claim</p>
        </div>
      )}
      {claimError && (
        <div className="flex items-center mt-4 p-4 bg-red-100 text-red-700 rounded-lg shadow-md">
          <div className="font-bold">Error:</div>
          <div className="ml-2">{claimError}</div>
        </div>
      )}
    </div>
  );
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllBetTypes,
  getBetTypesStatus,
  getBetTypesError,
  setBetTypes,
} from "../../app/features/bet/betTypeSlice";
import { fetchTypes } from "../../app/features/categories/typesSlice";
import { AppDispatch, RootState } from "../../app/store";

const useBetTypesData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const betTypes = useSelector(selectAllBetTypes);
  const betTypesStatus = "succeeded";
  const error = useSelector(getBetTypesError);
  const { selectedBetTypesId } = useSelector(
    (state: RootState) => state.betTypes
  );

  const handleTypeClick = (betTypeId: number) => {
    dispatch(setBetTypes(betTypeId));
  };

  return {
    betTypes,
    betTypesStatus,
    error,
    selectedBetTypesId,
    handleTypeClick,
  };
};

export default useBetTypesData;

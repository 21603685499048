import { useState } from "react";
import { ClaimButton } from "../../ClaimButton";
import { InviteButton } from "../../InviteButton";

export const InviteBonuse = ({
  task,
  taskDescription,
  taskImg,
  eligibleClaim,
  status,
  claimReferralReward,
}: {
  task?: string;
  taskDescription?: string;
  taskImg: string;
  eligibleClaim: boolean;
  status?: string;
  claimReferralReward: () => void;
}) => {
  return (
    <div
      className={`flex w-full items-center justify-between min-h-[70px] ${
        eligibleClaim
          ? "bg-green-200 border-green-700"
          : "bg-slate-100 border-slate-400"
      } rounded-lg border  p-2 gap-2`}
    >
      <div className="h-[60px] min-w-[47px] justify-center items-center inline-flex">
        <img className="" src={taskImg} />
      </div>
      <div className="flex-col justify-center items-start gap-2 inline-flex">
        {task ? (
          <div className="text-black text-sm font-bold font-['SF Pro Text'] leading-[16.80px] tracking-tight">
            {task}
          </div>
        ) : (
          ""
        )}
        {taskDescription ? (
          <div className=" text-slate-800 text-sm font-normal font-['SF Pro Text'] leading-[14px] tracking-tight">
            {taskDescription}
          </div>
        ) : (
          ""
        )}
      </div>

      {status == "inviteLink" ? (
        <InviteButton />
      ) : (
        <ClaimButton
          eligibleClaim={eligibleClaim}
          onClick={claimReferralReward}
        />
      )}
    </div>
  );
};

import React from "react";
import { CurrencyItem } from "./components";
import LoadingSpinner from "../../../../components/LoaderSpinner";
import ErrorMessage from "../../../../components/ErrorMessage";

export const CurrencyList = ({
  currencies,
  currencyStatus,
  error,
}: {
  currencies: any[];
  currencyStatus: string;
  error: string | null;
}) => {
  let content;

  if (currencyStatus === "loading") {
    content = <LoadingSpinner />;
  } else if (currencyStatus === "succeeded") {
    content = currencies.map((c) => <CurrencyItem key={c.currency} c={c} />);
  } else {
    content = <ErrorMessage error={error} />;
  }

  return (
    <div className="flex flex-grow h-full gap-x-2 leading-none text-sm items-center justify-center select-none px-4 py-4">
      {content}
    </div>
  );
};

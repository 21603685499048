import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import eventsApi from "../../../services/eventsApi";

export interface Type {
  id: number;
  name: string;
}
interface TypesState {
  types: Type[];
  selectedTypesId: number | null;
  status: string;
  error: string | null;
}
const initialState: TypesState = {
  types: [],
  selectedTypesId: 0,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchTypes = createAsyncThunk(
  "types/fetchCategories",
  async () => {
    return eventsApi.getAllTypes();
  }
);

const typesSlice = createSlice({
  name: "types",
  initialState,
  reducers: {
    setTypes: (state, action) => {
      state.selectedTypesId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTypes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.types = action.payload;
      })
      .addCase(fetchTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "An error occurred";
        // state.status = "succeeded";
      });
  },
});

export const { setTypes } = typesSlice.actions;

export const selectAllTypes = (state: { types: TypesState }) =>
  state.types.types;

export const getTypesStatus = (state: { types: TypesState }) =>
  state.types.status;

export const getTypesError = (state: { types: TypesState }) =>
  state.types.error;

export const selectTypeById = (state: { types: TypesState }, typeId: number) =>
  state.types.types.find((type) => type.id === typeId);

export default typesSlice.reducer;

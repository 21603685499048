import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useTransactionData from "./useTransactionData";
import { fetchUserTransactions } from "../../app/features/transactions/transactionsSlice";
import { AppDispatch } from "../../app/store";
import { TransactionsList } from "./components/TransactionsList";
import { useTonAddress } from "@tonconnect/ui-react";

export const Transactions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    transactions,
    transactionsStatus,
    error,
    hasNextPage,
    currentPage,
    perPage,
    user,
    setInitialTransactions,
  } = useTransactionData();

  const wallet = useTonAddress();
  useEffect(() => {
    setInitialTransactions();
  }, [dispatch, perPage]);

  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setShowScrollButton(currentScrollY > 300);

    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 50 &&
      hasNextPage &&
      transactionsStatus !== "loading"
    ) {
      dispatch(
        fetchUserTransactions({
          page: currentPage,
          perPage: perPage,
          wallet,
        })
      );
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className="px-4 py-4">
      <TransactionsList
        transactions={transactions}
        transactionsStatus={transactionsStatus}
        error={error}
        handleScroll={handleScroll}
        showScrollButton={showScrollButton}
        scrollToTop={scrollToTop}
      />
    </section>
  );
};

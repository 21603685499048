// walletSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WalletState {
  tonBalance: number | null;
}

const initialState: WalletState = {
  tonBalance: null,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setTonBalance: (state, action: PayloadAction<number | null>) => {
      state.tonBalance = action.payload;
    },
  },
});

export const { setTonBalance } = walletSlice.actions;
export default walletSlice.reducer;

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllBets,
  getBetsStatus,
  getBetsError,
  selectBetsCurrentPage,
  setBetsCurrentPage,
  setBets,
  fetchBets,
} from "../../app/features/bet/betSlice";
import {
  selectHasNextPage,
  selectPerPage,
} from "../../app/features/events/eventsSlice";
import { AppDispatch, RootState } from "../../app/store";
import { useTelegram } from "../../hooks/useTelegram";

const useBetsData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const bets = useSelector(selectAllBets);
  const betsStatus = useSelector(getBetsStatus);
  const error = useSelector(getBetsError);
  const hasNextPage = useSelector(selectHasNextPage);
  const currentPage = useSelector(selectBetsCurrentPage);
  const perPage = useSelector(selectPerPage);
  const { user } = useTelegram();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { selectedBetTypesId } = useSelector(
    (state: RootState) => state.betTypes
  );

  useEffect(() => {
    dispatch(setBetsCurrentPage(1));
    dispatch(setBets([]));
    try {
      dispatch(
        fetchBets({
          page: 1,
          perPage,
          clientId: user.id,
          status: selectedBetTypesId,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, selectedBetTypesId, perPage]);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > 300) {
      if (currentScrollY > previousScrollY.current) {
        setShowScrollButton(false);
      } else if (currentScrollY < previousScrollY.current) {
        setShowScrollButton(true);
      }
    } else {
      setShowScrollButton(false);
    }
    previousScrollY.current = currentScrollY;

    if (betsStatus === "loading") {
      return;
    }

    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 50 &&
      hasNextPage &&
      betsStatus !== "loading"
    ) {
      try {
        dispatch(
          fetchBets({
            page: currentPage,
            perPage,
            clientId: user.id,
            status: selectedBetTypesId,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  let previousScrollY = useRef(0);

  return {
    bets,
    betsStatus,
    error,
    showScrollButton,
    handleScroll,
    scrollToTop,
  };
};

export default useBetsData;

import { Transaction } from "../../../../../../app/features/transactions/transactionsSlice";
import { TransactionItemDetails } from "./components";

export const TransactionItem = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  return (
    <>
      <TransactionItemDetails transaction={transaction} />
      <div className="border-t border-transactionsLineColor my-4"></div>
    </>
  );
};

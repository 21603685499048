import React from "react";
import { Link } from "react-router-dom";

interface FooterNavItemProps {
  to: string;
  paths: string[];
  label: string;
  active: boolean;
  onClick: () => void;
}

export const FooterNavItem: React.FC<FooterNavItemProps> = ({
  to,
  paths,
  label,
  active,
  onClick,
}) => {
  return (
    <div className="px-4">
      <Link to={to}>
        <div
          className="flex flex-col items-center justify-center select-none"
          onClick={onClick}
        >
          <svg
            className={`${
              active ? "opacity-100" : "opacity-50"
            } transition-opacity cursor-pointer`}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {paths.map((d, index) => (
              <path
                key={index}
                d={d}
                fill={active ? "#007AFF" : "#87A6BB"}
                fillRule={to === "/mywallet" || "/settings" ? "evenodd" : undefined}
                clipRule={to === "/mywallet" ? "/settings" : undefined}
              />
            ))}
          </svg>
          <p
            className={`text-xxs font-medium ${
              active
                ? "text-activeFooterTextColor font-semibold"
                : "text-defaultFooterTextColor"
            }`}
          >
            {label}
          </p>
        </div>
      </Link>
    </div>
  );
};

import { useTimer } from "react-timer-hook";
import { useDispatch } from "react-redux";
import {
  setIsEventStatus,
  EventStatus,
} from "../../../../../../app/features/events/eventsSlice";
import { AppDispatch } from "../../../../../../app/store";

const useEventTimer = (eventId: number, startDate: string) => {
  const dispatch = useDispatch<AppDispatch>();

  const date = new Date(startDate);
  const time = new Date();
  const timeDiff = date.getTime() - time.getTime();
  time.setMilliseconds(time.getMilliseconds() + timeDiff);

  const { seconds, minutes, hours, days, isRunning } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      dispatch(setIsEventStatus({ id: eventId, status: EventStatus.started }));
    },
  });

  return { seconds, minutes, hours, days, isRunning };
};

export default useEventTimer;

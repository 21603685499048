import { useSelector } from "react-redux";
import { Category } from "../../../../../../app/features/categories/categoriesSlice";
import { selectTypeById } from "../../../../../../app/features/categories/typesSlice";
import { RootState } from "../../../../../../app/store";

export const CategoryItem = ({ category }: { category: Category }) => {
  const { selectedCategoryId } = useSelector(
    (state: RootState) => state.categories
  );
  const type = useSelector((state: RootState) =>
    selectTypeById(state, category.typeId)
  );

  return (
    <>
      <div className="flex flex-col justify-center items-center ">
        <div
          className={`flex items-center justify-center p-0.5 mb-1 ${
            selectedCategoryId === category.id
              ? "w-14 h-16 border border-slate-400 bg-zinc-100 rounded-[5px]"
              : "w-10 h-14"
          }`}
        >
          <img
            alt={category.name}
            src={`./${type?.name.toLowerCase()}/${category.name
              .toLowerCase()
              .split(" ")
              .join("")}.svg`}
            className={`w-10 h-10 rounded-md`}
          />
        </div>
        <p
          className={`text-center text-black text-xs  font-['SF Pro Text'] leading-3 whitespace-pre-line w-16 ${
            selectedCategoryId === category.id ? "font-bold" : "font-normal"
          }`}
        >
          {category.name}
        </p>
      </div>
    </>
  );
};

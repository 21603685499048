import React from "react";

export const DisconnectIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0252 2.60975C9.25925 3.289 9.25925 4.53158 9.25925 7.01566V18.9843C9.25925 21.4684 9.25925 22.711 10.0252 23.3902C10.7911 24.0695 11.9622 23.8658 14.3043 23.4574L16.8285 23.0176C19.422 22.5647 20.7187 22.3383 21.489 21.3872C22.2592 20.4349 22.2592 19.0591 22.2592 16.3063V9.69366C22.2592 6.942 22.2592 5.56616 21.4901 4.61391C20.7187 3.66275 19.4209 3.43633 16.8274 2.98458L14.3054 2.54366C11.9632 2.13525 10.7922 1.93158 10.0262 2.61083M12.5092 11.0164C12.9577 11.0164 13.3217 11.3967 13.3217 11.8657V14.1342C13.3217 14.6033 12.9577 14.9836 12.5092 14.9836C12.0607 14.9836 11.6967 14.6033 11.6967 14.1342V11.8657C11.6967 11.3967 12.0607 11.0164 12.5092 11.0164Z"
      fill="#E7393F"
    />
    <path
      d="M8.17592 4.875C5.94642 4.87825 4.784 4.927 4.043 5.668C3.25 6.461 3.25 7.73717 3.25 10.2917V15.7083C3.25 18.2618 3.25 19.5379 4.043 20.332C4.784 21.0719 5.94642 21.1217 8.17592 21.125C8.125 20.449 8.125 19.669 8.125 18.8251V7.17492C8.125 6.32992 8.125 5.54992 8.17592 4.875Z"
      fill="#E7393F"
    />
  </svg>
);

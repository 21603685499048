interface ClaimButtonProps {
  onClick: () => void;
  eligibleClaim: boolean;
  loading?: boolean;
}

export const ClaimButton: React.FC<ClaimButtonProps> = ({
  onClick,
  eligibleClaim,
  loading,
}) => {
  return eligibleClaim ? (
    <button
      className="cursor-pointer select-none min-w-[72px] h-12 pb-1 bg-green-700 rounded-md shadow-lg border border-black relative transform transition-transform duration-150 active:scale-95"
      onClick={onClick}
    >
      <div className="relative inline-flex items-center justify-center w-full h-full bg-gradient-to-b from-lime-400 to-green-500 rounded-md ">
        <div className="absolute inset-0 rounded-md bg-gradient-to-b from-transparent to-black opacity-20"></div>
        <div className="relative">
          <span className="relative text-white text-base font-extrabold font-['SF Pro Text'] leading-none tracking-wider text-stroke-3">
            {loading ? "Loading..." : "Claim"}
          </span>
        </div>
      </div>
      <div
        className="absolute inset-0 rounded-md border-b-2 border-black"
        style={{ filter: "blur(2px)" }}
      ></div>
    </button>
  ) : (
    <button
      disabled={true}
      className="min-w-[72px] select-none h-12 pb-1 bg-gray-700 rounded-md shadow-lg border border-black relative"
    >
      <div className="relative inline-flex items-center justify-center w-full h-full bg-gradient-to-b from-stone-300 to-gray-500 rounded-md ">
        <div className="absolute inset-0 rounded-md bg-gradient-to-b from-transparent to-black opacity-20"></div>
        <div className="relative">
          <span className="relative text-white opacity-40 text-base font-extrabold font-['SF Pro Text'] leading-none tracking-wider text-stroke-3">
            Claim
          </span>
        </div>
      </div>
    </button>
  );
};

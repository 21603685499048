import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import eventsApi from "../../../services/eventsApi";

enum BET_TYPES {
  active,
  history,
}

const betTypes = Object.values(BET_TYPES)
  .slice(0, Object.keys(BET_TYPES).length / 2)
  .map((v, i) => ({
    id: i,
    name: v.toString().toUpperCase().split("_").join(" "),
  }));

export interface BetType {
  id: number;
  name: string;
}
interface BetTypesState {
  betTypes: BetType[];
  selectedBetTypesId: number;
  status: string;
  error: string | null;
}
const initialState: BetTypesState = {
  betTypes: betTypes,
  selectedBetTypesId: 0,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchTypes = createAsyncThunk(
  "betTypes/fetchCategories",
  async () => {
    return eventsApi.getAllTypes();
  }
);

const BetTypesSlice = createSlice({
  name: "betTypes",
  initialState,
  reducers: {
    setBetTypes: (state, action) => {
      state.selectedBetTypesId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTypes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.betTypes = action.payload;
      })
      .addCase(fetchTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "An error occurred";
        // state.status = "succeeded";
      });
  },
});

export const { setBetTypes } = BetTypesSlice.actions;

export const selectAllBetTypes = (state: { betTypes: BetTypesState }) =>
  state.betTypes.betTypes;

export const getBetTypesStatus = (state: { betTypes: BetTypesState }) =>
  state.betTypes.status;

export const getBetTypesError = (state: { betTypes: BetTypesState }) =>
  state.betTypes.error;

export const selectBetTypeById = (
  state: { betTypes: BetTypesState },
  betTypeId: number
) => state.betTypes.betTypes.find((betTypes) => betTypes.id === betTypeId);

export default BetTypesSlice.reducer;

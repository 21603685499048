import { FavoriteEvent } from "../app/features/events/favoritesEventsSlice";
import { BACKEND_KEYS } from "../common/consts";

import WebSocketClient from "./WebSocketClient";

const data = { agent: "event", lang: "en", auth: { type: "webSite" } };

class FavoritesEventsApi {
  private webSocketClient: WebSocketClient;

  constructor() {
    this.webSocketClient = WebSocketClient.getInstance(BACKEND_KEYS.SERVER_URL);
  }

  async getAllFavoritesIds(clientId: string) {
    return this.webSocketClient.request<number[]>({
      method: "eventEndpoints_allFavoritesIds",
      params: {
        clientId: String(clientId),
      },
      ...data,
    });
  }

  async getAllFavorites(clientId: string, page: number, perPage: number) {
    return this.webSocketClient.request<FavoriteEvent>({
      method: "eventEndpoints_getAllFavourites",
      params: {
        clientId: String(clientId),
        page,
        perPage,
      },
      ...data,
    });
  }

  async getFavoritesById(clientId: string, eventId: number) {
    return this.webSocketClient.request<boolean>({
      method: "eventEndpoints_getById",
      params: {
        clientId: String(clientId),
        eventId,
      },
      ...data,
    });
  }

  async addEventToFavorites(clientId: string, eventId: number) {
    return this.webSocketClient.request<number>({
      method: "eventEndpoints_addFavoriteById",
      params: {
        clientId: String(clientId),
        eventId,
      },
      ...data,
    });
  }

  async deleteEventFromFavorites(clientId: string, eventId: number) {
    return this.webSocketClient.request<number>({
      method: "eventEndpoints_deleteFavoriteById",
      params: {
        clientId: String(clientId),
        eventId,
      },
      ...data,
    });
  }
}

const favoritesEventsApi = new FavoritesEventsApi();
export default favoritesEventsApi;

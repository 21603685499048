import { Bet } from "../../../../../../app/features/bet/betSlice";

const useBetCalculations = (bet: Bet) => {
  const outcame = bet.pool.name;
  const win = bet.pool.isWin;

  let sum = bet.value * (1 / (bet.pool.totalSum / bet.event.totalSum));
  const margin = 0.04 / 2;
  let commision = (sum / 100) * 2;
  let potensialWinning = sum - commision;

  let coefficient =
    bet.pool.totalSum > 0
      ? ((1 / (bet.pool.totalSum / bet.event.totalSum)) * (1 - margin)).toFixed(
          2
        )
      : bet.event.totalSum > 0
      ? 0
      : 1;

  return { outcame, win, coefficient, potensialWinning };
};

export default useBetCalculations;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ErrorMessage from "../../../../components/ErrorMessage";
import LoadingSpinner from "../../../../components/LoaderSpinner";

export const BetTypesList = ({
  betTypes,
  betTypesStatus,
  error,
  selectedBetTypesId,
  handleTypeClick,
}: {
  betTypes: any[];
  betTypesStatus: string;
  error: string | null;
  selectedBetTypesId: number | null;
  handleTypeClick: (betTypeId: number) => void;
}) => {
  let content;

  if (betTypesStatus === "loading") {
    content = <LoadingSpinner />;
  } else if (betTypesStatus === "succeeded") {
    content = betTypes.map((status) => (
      <SwiperSlide key={status.id} className="!w-auto select-none">
        <button
          key={status.id}
          className={`flex flex-grow cursor-pointer px-6 py-2 text-xs font-medium text-black items-center justify-center  ${
            selectedBetTypesId === status.id
              ? "bg-white rounded-md shadow-md font-semibold"
              : ""
          }`}
          onClick={() => handleTypeClick(status.id)}
        >
          {status.name}
        </button>
      </SwiperSlide>
    ));
  } else {
    content = <ErrorMessage error={error} />;
  }

  return (
    <section className="p-4 pb-0">
      <Swiper
        centerInsufficientSlides
        slidesPerView={"auto"}
        spaceBetween={0}
        width={null}
        className="!py-0.5 !px-0.5 bg-swiperBg flex flex-row rounded-lg "
      >
        {content}
      </Swiper>
    </section>
  );
};

import { Currency } from "../app/features/currency/currencySlice";
import { User } from "../app/features/user/userSlice";
import { BACKEND_KEYS } from "../common/consts";

import WebSocketClient from "./WebSocketClient";

const data = { agent: "event", lang: "en", auth: { type: "webSite" } };

class UsersApi {
  private webSocketClient: WebSocketClient;

  constructor() {
    this.webSocketClient = WebSocketClient.getInstance(BACKEND_KEYS.SERVER_URL);
  }

  async login(userData: User): Promise<any> {
    return this.webSocketClient.request<User>({
      method: "eventEndpoints_createUser",
      params: userData,
      ...data,
    });
  }

  async getUserSettings(clientId: string): Promise<any> {
    return this.webSocketClient.request({
      method: "eventEndpoints_getUserSettings",
      params: { clientId: String(clientId) },
      ...data,
    });
  }

  async updateUserSettings(
    clientId: string,
    settings: {
      isAgreeToGetClaimNotification?: boolean;
      isAgreeToGetEventEndNotification?: boolean;
      isAgreeToGetReferralBonusNotification?: boolean;
    }
  ): Promise<void> {
    return this.webSocketClient.request({
      method: "eventEndpoints_updateUserSettings",
      params: { clientId: String(clientId), settings },
      ...data,
    });
  }
}

const usersApi = new UsersApi();

export default usersApi;

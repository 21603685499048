import moment from "moment";

import { IncomeIcon, ExpenseIcon } from "./Icons";

import { CURRENCY } from "../../../../../../../../app/features/currency/currencySlice";
import {
  Transaction,
  TransactionType,
} from "../../../../../../../../app/features/transactions/transactionsSlice";

export const TransactionItemDetails = ({
  transaction,
}: {
  transaction: Transaction;
}) => {
  const extraDetails = transaction.extraDetails ?? {};
  const { eventName, matchName } = extraDetails;

  const transactionReasonsName = [
    "BET",
    "WIN",
    "CLAIM",
    "Reward",
  ];

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row items-center ">
        <div className="flex flex-col text-center justify-center">
          <div
            className={`w-10 h-10 rounded-full ${
              transaction.type === TransactionType.in
                ? "bg-green-200"
                : "bg-red-200"
            }  flex justify-center items-center`}
          >
            {transaction.type === TransactionType.in ? (
              <IncomeIcon />
            ) : (
              <ExpenseIcon />
            )}
          </div>
          <p
            className={`mt-0.5 text-xs font-medium leading-none ${
              transaction.type === TransactionType.in
                ? "text-greenWinColor"
                : "text-black"
            } whitespace-pre-line`}
          >
            {transactionReasonsName[transaction.reason]}
          </p>
        </div>
        <div className="ml-2 leading-tight">
          {eventName && (
            <div className="text-xs text-customGray pb-1">{eventName}</div>
          )}
          {matchName && (
            <div className="text-sm font-medium text-black">{matchName}</div>
          )}
        </div>
      </div>
      <div className="flex flex-col h-full leading-none whitespace-nowrap ml-3 ">
        <div
          className={` ${
            transaction.type === TransactionType.in
              ? "text-greenWinColor"
              : "text-black"
          } font-semibold text-base  text-end pb-1`}
        >
          {transaction.type === TransactionType.in ? "+" : "-"}
          {transaction.amount} {CURRENCY[transaction.currency].toUpperCase()}
        </div>
        <div className="text-xs text-customGray text-end font-light">
          {moment(transaction.cratedAt).format("YYYY-MM-DD HH:mm")}
        </div>
      </div>
    </div>
  );
};

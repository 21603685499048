import React from "react";
import useCurrencyData from "./useCurrencyData";
import { CurrencyList } from "./components";

export const CurrencyComponent = () => {
  const { currencies, currencyStatus, error } = useCurrencyData();

  return (
    <CurrencyList
      currencies={currencies}
      currencyStatus={currencyStatus}
      error={error}
    />
  );
};


import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { CHAIN, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";

import { Transactions } from "../containers/Transactions";
import { ClaimButton } from "../containers/Claim";
import { Swiper, SwiperSlide } from "swiper/react";
import { CurrencyComponent } from "../containers";
import { InviteBonuses } from "../components/InviteBonuses";
import { InviteText } from "../components/InviteText";
import { Percentages } from "../components/Percentages";

const MyWalletPage = () => {
  const [autoScrollToTop, setAutoScrollToTop] = useState(true);

  const location = useLocation();
  const [selectedType, setSelectedType] = useState("Claim");
  useEffect(() => {
    if (autoScrollToTop) {
      window.scrollTo(0, 0); // Fallback to window.scrollTo if needed
      setAutoScrollToTop(false);
    }
  }, [autoScrollToTop, location]);

  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const types = ["Transactions", "Claim", "Bonuses"];

  const content = types.map((type) => (
    <button
      key={type}
      className={`flex-1 h-full cursor-pointer py-2 text-xs font-medium text-black items-center justify-center ${
        selectedType === type
          ? "bg-white rounded-md shadow-md font-semibold"
          : ""
      }`}
      onClick={() => setSelectedType(type)}
    >
      <div className="flex justify-center items-center">{type}</div>
    </button>
  ));

  return (
    <main>
      {address && tonConnectUI.account?.chain === CHAIN.MAINNET ? (
        <div>
          <CurrencyComponent />
          <div className="px-4">
            <div className="flex w-full !py-0.5 !px-0.5 bg-swiperBg rounded-lg gap-0">
              {content}
            </div>
          </div>
          {selectedType === "Bonuses" && (
            <div className="p-4">
              <InviteText />
              <InviteBonuses />
              <Percentages />
              {/* <FriendsList /> */}
            </div>
          )}
          {selectedType === "Claim" && <ClaimButton />}
          {selectedType === "Transactions" && <Transactions />}
        </div>
      ) : (
        ""
      )}
    </main>
  );
};
export default MyWalletPage;

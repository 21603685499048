import {
  RequestWebsocket,
  ResponseWebsocket,
} from "../interfaces/wsInterfaces";

type Listener = (data: any) => void;

class WebSocketClient {
  private static instance: WebSocketClient;
  public socket: WebSocket;
  private isConnected: boolean = false;
  private messageQueue: any[] = [];
  private eventListeners: { [key: string]: Listener[] } = {};
  private responseHandlers: { [key: string]: (response: any) => void } = {};

  private constructor(url: string) {
    this.socket = new WebSocket(url);

    this.socket.onopen = () => {
      console.log("WebSocket connection opened");
      this.isConnected = true;
      this.processQueue();
    };

    this.socket.onclose = () => {
      console.log("WebSocket connection closed");
      this.isConnected = false;
    };

    this.socket.onerror = (error) => {
      console.error(`WebSocket error: ${error}`);
    };

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      this.handleResponse(data);
    };
  }

  public static getInstance(url: string): WebSocketClient {
    if (!WebSocketClient.instance) {
      WebSocketClient.instance = new WebSocketClient(url);
    }
    return WebSocketClient.instance;
  }

  private processQueue() {
    while (this.messageQueue.length > 0 && this.isConnected) {
      const message = this.messageQueue.shift();
      this.send(message);
    }
  }

  public send<T>(message: T) {
    if (this.isConnected) {
      this.socket.send(JSON.stringify(message));
    } else {
      this.messageQueue.push(message);
    }
  }

  public subscribe(eventType: string, listener: Listener) {
    if (!this.eventListeners[eventType]) {
      this.eventListeners[eventType] = [];
    }
    this.eventListeners[eventType].push(listener);
  }

  public unsubscribe(eventType: string, listener: Listener) {
    if (this.eventListeners[eventType]) {
      this.eventListeners[eventType] = this.eventListeners[eventType].filter(
        (l) => l !== listener
      );
    }
  }

  public request<T>(config: Omit<RequestWebsocket, "id">): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const id = `${Date.now()}-${Math.random()}`;
      this.responseHandlers[id] = (
        response: ResponseWebsocket["result"]["response"]
      ) => {
        const { type, value } = response;
        if (type === "Right") {
          resolve(value);
        } else if (type === "Left") {
          reject(value);
        }
        delete this.responseHandlers[id];
      };
      this.send({
        ...config,
        id,
      });
    });
  }
  private handleResponse(data: ResponseWebsocket) {
    const { response, request } = data.result;
    const { id } = request;
    if (this.responseHandlers[id]) {
      this.responseHandlers[id](response);
    }
  }
}

export default WebSocketClient;

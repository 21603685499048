import React from "react";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { DisconnectIcon } from "./Icons";

export const DisconnectButton = () => {
  const [tonConnectUI] = useTonConnectUI();

  const onDisconnect = async () => {
    await tonConnectUI.disconnect();
  };

  return (
    <div className="relative flex items-center select-none">
      <DisconnectIcon />
      <button
        type="button"
        className="flex items-center gap-x-1 text-xs font-semibold leading-6 text-disconnectColor"
        aria-expanded="false"
        onClick={onDisconnect}
      >
        Disconnect
      </button>
    </div>
  );
};

import { useTonAddress } from "@tonconnect/ui-react";
import { useTelegram } from "../../../../hooks/useTelegram";

export const InviteButton = () => {
  const { tg } = useTelegram();
  const address = useTonAddress();

  const handleInviteClick = () => {
    if (!address) return;
    const url = `https://t.me/BET_TON_APP_BOT/get_free_bets?startapp=${address}`;

    const text = `Use this link to get 500 bonus BETCOIN for both of you and your friend`;
    if (tg) {
      tg.openTelegramLink(`https://t.me/share/url?url=${url}&text=${text}`);
    }
  };
  return (
    <button
      onClick={handleInviteClick}
      className="w-full mr-1 h-[50px] px-6 bg-blue-600 rounded-xl justify-center items-center inline-flex"
    >
      <div className="w-1.5 h-[22px] "></div>
      <div className="flex gap-1 items-center justify-center">
        <svg
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.2334 13.8667C16.4711 13.8667 17.6581 14.3583 18.5333 15.2335C19.4084 16.1087 19.9001 17.2956 19.9001 18.5333V19.4667C19.9001 19.9617 19.7034 20.4365 19.3534 20.7866C19.0033 21.1367 18.5285 21.3333 18.0334 21.3333H4.96676C4.47169 21.3333 3.9969 21.1367 3.64683 20.7866C3.29676 20.4365 3.1001 19.9617 3.1001 19.4667V18.5333C3.1001 17.2956 3.59176 16.1087 4.46693 15.2335C5.3421 14.3583 6.52909 13.8667 7.76676 13.8667H15.2334ZM18.9668 8.26666C19.2143 8.26666 19.4517 8.36499 19.6267 8.54002C19.8018 8.71506 19.9001 8.95245 19.9001 9.19999V10.1333H20.8334C21.081 10.1333 21.3184 10.2317 21.4934 10.4067C21.6684 10.5817 21.7668 10.8191 21.7668 11.0667C21.7668 11.3142 21.6684 11.5516 21.4934 11.7266C21.3184 11.9017 21.081 12 20.8334 12H19.9001V12.9333C19.9001 13.1809 19.8018 13.4183 19.6267 13.5933C19.4517 13.7683 19.2143 13.8667 18.9668 13.8667C18.7192 13.8667 18.4818 13.7683 18.3068 13.5933C18.1318 13.4183 18.0334 13.1809 18.0334 12.9333V12H17.1001C16.8526 12 16.6152 11.9017 16.4401 11.7266C16.2651 11.5516 16.1668 11.3142 16.1668 11.0667C16.1668 10.8191 16.2651 10.5817 16.4401 10.4067C16.6152 10.2317 16.8526 10.1333 17.1001 10.1333H18.0334V9.19999C18.0334 8.95245 18.1318 8.71506 18.3068 8.54002C18.4818 8.36499 18.7192 8.26666 18.9668 8.26666ZM11.5001 2.66666C12.7378 2.66666 13.9248 3.15832 14.7999 4.03349C15.6751 4.90866 16.1668 6.09565 16.1668 7.33332C16.1668 8.571 15.6751 9.75798 14.7999 10.6332C13.9248 11.5083 12.7378 12 11.5001 12C10.2624 12 9.07544 11.5083 8.20027 10.6332C7.3251 9.75798 6.83343 8.571 6.83343 7.33332C6.83343 6.09565 7.3251 4.90866 8.20027 4.03349C9.07544 3.15832 10.2624 2.66666 11.5001 2.66666Z"
            fill="white"
          />
        </svg>

        <div className="text-center text-white text-[17px] font-medium font-['SF Pro Text'] leading-snug">
          Invite a friend
        </div>
      </div>

      <div className="w-1 h-[22px] "></div>
    </button>
  );
};

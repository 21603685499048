export const InviteText = () => {
  return (
    <div className="w-full flex-col justify-start items-center gap-3 inline-flex pb-6">
      <div className="self-stretch text-center text-black text-2xl font-bold font-['SF Pro Text'] leading-normal">
        Invite your friends!
      </div>
      <div className="self-stretch text-center text-slate-400 text-sm font-normal font-['SF Pro Text'] leading-[14px]">
        You and your friend will receive bonuses
      </div>
    </div>
  );
};

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { CategoryItem } from "./components";
import LoadingSpinner from "../../../../components/LoaderSpinner";
import ErrorMessage from "../../../../components/ErrorMessage";

export const CategoriesList = ({
  categories,
  categoriesStatus,
  error,
  handleCategoryClick,
}: {
  categories: any[];
  categoriesStatus: string;
  error: string | null;
  handleCategoryClick: (categoryId: number) => void;
}) => {
  if (!categories.length) {
    return <LoadingSpinner />;
  }

  const renderedCategories = categories.map((category) => (
    <SwiperSlide
      key={category.id}
      className="text-center !w-auto cursor-pointer first:ml-4 select-none"
      onClick={() => handleCategoryClick(category.id)}
    >
      <CategoryItem category={category} />
    </SwiperSlide>
  ));

  let content =
    categoriesStatus === "loading" ? (
      <LoadingSpinner />
    ) : categoriesStatus === "succeeded" ? (
      <Swiper
        centerInsufficientSlides
        slidesPerView={"auto"}
        spaceBetween={8}
        className="mySwiper !pr-4"
      >
        {renderedCategories}
      </Swiper>
    ) : (
      <ErrorMessage error={error} />
    );

  return <section className="pb-[10px] -mx-4">{content}</section>;
};

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllEvents,
  getEventsStatus,
  getEventsError,
  selectHasNextPage,
  selectCurrentPage,
  selectPerPage,
  setEventsCurrentPage,
  setEvents,
  fetchEvents,
} from "../../app/features/events/eventsSlice";
import {
  selectAllFavoritesEventsId,
  deleteEventFromFavorites,
  addEventToFavorites,
  fetchAllFavoritesIds,
} from "../../app/features/events/favoritesEventsSlice";
import { AppDispatch, RootState } from "../../app/store";
import { useTelegram } from "../../hooks/useTelegram";
import favoritesEventsApi from "../../services/favoritesEventsApi";

const useEventsData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const events = useSelector(selectAllEvents);
  const favoritesId = useSelector(selectAllFavoritesEventsId);
  const eventStatus = useSelector(getEventsStatus);
  const error = useSelector(getEventsError);
  const hasNextPage = useSelector(selectHasNextPage);
  const currentPage = useSelector(selectCurrentPage);
  const perPage = useSelector(selectPerPage);
  const { user } = useTelegram();
  const { selectedTypesId } = useSelector((state: RootState) => state.types);
  const { selectedCategoryId } = useSelector(
    (state: RootState) => state.categories
  );
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleFavorite = async (id: number) => {
    try {
      const response = await favoritesEventsApi.getFavoritesById(user.id, id);

      if (response) {
        dispatch(deleteEventFromFavorites({ clientId: user.id, eventId: id }));
      } else {
        dispatch(addEventToFavorites({ clientId: user.id, eventId: id }));
      }
    } catch (error) {
      console.error("Error occurred while handling favorite:", error);
    }
  };

  useEffect(() => {
    try {
      if (selectedTypesId !== null) {
        dispatch(fetchAllFavoritesIds(user.id));
        dispatch(setEventsCurrentPage(1));
        dispatch(setEvents([]));
        dispatch(
          fetchEvents({
            page: 1,
            perPage,
            typeId: selectedTypesId,
            categoryId:
              typeof selectedCategoryId === "number"
                ? selectedCategoryId
                : null,
          })
        );
      }
    } catch (error) {}
  }, [selectedTypesId, selectedCategoryId, dispatch, perPage]);

  let previousScrollY = useRef(0);
  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > 300) {
      if (currentScrollY > previousScrollY.current) {
        setShowScrollButton(false);
      } else if (currentScrollY < previousScrollY.current) {
        setShowScrollButton(true);
      }
    } else {
      setShowScrollButton(false);
    }
    previousScrollY.current = currentScrollY;

    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 50 &&
      hasNextPage &&
      eventStatus !== "loading"
    ) {
      try {
        if (selectedTypesId !== null) {
          dispatch(
            fetchEvents({
              page: currentPage,
              perPage,
              typeId: selectedTypesId,
              categoryId: selectedCategoryId,
            })
          );
        }
      } catch (error) {}
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return {
    events,
    favoritesId,
    eventStatus,
    error,
    showScrollButton,
    handleFavorite,
    handleScroll,
    scrollToTop,
  };
};

export default useEventsData;

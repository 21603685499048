import {
  EventStatus,
  IEvent,
} from "../../../../../../../../app/features/events/eventsSlice";
import useEventTimer from "../../useEventTimer";

export const Timer = ({
  id,
  status,
  startDate,
}: {
  id: number;
  status: number;
  startDate: string;
}) => {
  const { seconds, minutes, hours, days, isRunning } = useEventTimer(
    id,
    startDate
  );
  const formatTime = (
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  ) => {
    const showDays = days > 0;
    const showHours = hours > 0 || showDays;
    const showMinutes = minutes > 0 || showHours;
    const showSeconds = seconds > 0 || showMinutes;

    let timeString = "";
    if (showDays) {
      timeString += `${days}:`;
    }
    if (showHours) {
      timeString += `${hours}:`;
    }
    if (showMinutes) {
      timeString += `${minutes}:`;
    }
    if (showSeconds) {
      timeString += `${seconds}`;
    }

    return timeString;
  };

  return (
    <div className="text-center mb-1 leading-none">
      <span className="text-xs text-customGray">
        {status === EventStatus.started ? (
          "Odds fixed"
        ) : isRunning ? (
          <div>
            <div>
              {"Event will start in "}
              {formatTime(days, hours, minutes, seconds)}
            </div>
            {new Date(startDate).toLocaleString()}
          </div>
        ) : (
          "Odds fixed"
        )}
      </span>
    </div>
  );
};

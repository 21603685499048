import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ErrorMessage from "../../../../components/ErrorMessage";
import LoadingSpinner from "../../../../components/LoaderSpinner";
import NoActivityPage from "../../../../components/NoActivityPage";
import ScrollHandler from "../../../../components/ScrollHandler";
import ScrollToTopButton from "../../../../components/ScrollToTopButton";
import { EventItem } from "./components";

export const EventsList = ({
  events,
  favoritesId,
  eventStatus,
  error,
  showScrollButton,
  handleFavorite,
  handleScroll,
  scrollToTop,
}: {
  events: any[];
  favoritesId: number[];
  eventStatus: string;
  error: string | null;
  showScrollButton: boolean;
  handleFavorite: (id: number) => void;
  handleScroll: () => void;
  scrollToTop: () => void;
}) => {
  const renderedEvents = events.map((event, i) => (
    <EventItem
      key={i}
      event={event}
      handleFavorite={handleFavorite}
      favoritesDiv={favoritesId}
    />
  ));

  let content =
    eventStatus === "failed" ? (
      <ErrorMessage error={error} />
    ) : eventStatus === "loading" && renderedEvents.length < 1 ? (
      <LoadingSpinner />
    ) : renderedEvents.length ? (
      <>
        <ScrollHandler onScroll={handleScroll} />
        <ScrollToTopButton
          showScrollButton={showScrollButton}
          onClick={scrollToTop}
        />
        {renderedEvents}
        {eventStatus === "loading" ? <LoadingSpinner /> : null}
      </>
    ) : (
      <NoActivityPage text={"No events yet"} />
    );

  return (
    // <section className="px-4 z-20 -mx-4 bg-grayBg minHeightGray overflow:hidden">
    <section className="px-4 pb-4 z-20 -mx-4 ">{content}</section>
  );
};

import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Layout } from "../../layout";
import FavoritesPage from "../../pages/FavoritesPage";
import HomePage from "../../pages/HomePage";
import MyBetsPage from "../../pages/MyBetsPage";
import MyWalletPage from "../../pages/MyWalletPage";
import NotFound from "../../pages/NotFound";
import { Friends } from "../../pages/Friends";
import { SettingsPage } from "../../pages/Settings";

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="/favorites" element={<FavoritesPage />} />
        <Route path="/mybets" element={<MyBetsPage />} />
        <Route path="/mywallet" element={<MyWalletPage />} />
        <Route path="/friends" element={<Friends />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

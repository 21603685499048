import { Category } from "../app/features/categories/categoriesSlice";
import { Type } from "../app/features/categories/typesSlice";
import { IEvent } from "../app/features/events/eventsSlice";
import { BACKEND_KEYS } from "../common/consts";

import WebSocketClient from "./WebSocketClient";

const data = { agent: "event", lang: "en", auth: { type: "webSite" } };

class EventsApi {
  private webSocketClient: WebSocketClient;

  constructor() {
    this.webSocketClient = WebSocketClient.getInstance(BACKEND_KEYS.SERVER_URL);
  }

  async getAllTypes() {
    return this.webSocketClient.request<Type[]>({
      method: "eventEndpoints_getTypes",
      params: {},
      ...data,
    });
  }

  // getTypeById(id: number) {
  //   return this.webSocketClient.request<Type>({
  //     method: "eventEndpoints_getTypeById",
  //     params: {
  //       id,
  //     },
  //   });
  // }

  async getAllCategories(typeId?: number) {
    return this.webSocketClient.request<Category[]>({
      method: "eventEndpoints_getCategories",
      params: {
        typeId,
      },
      ...data,
    });
  }

  // getCategoryById(id: number) {
  //   return this.webSocketClient.request<Category>({
  //     method: "eventEndpoints_getCategoryById",
  //     params: {
  //       id,
  //     },
  //   });
  // }

  async getAllEvents(
    page: number,
    perPage: number,
    typeId?: number,
    categoryId?: number | null | undefined
  ) {
    const params: any = { page, perPage, typeId };

    if (categoryId !== null && categoryId !== undefined) {
      params.categoryId = categoryId;
    }

    return this.webSocketClient.request<IEvent>({
      method: "eventEndpoints_getEvents",
      params,
      ...data,
    });
  }

  // Dont use yet
  async getEventById(id: number) {
    return this.webSocketClient.request<IEvent>({
      method: "eventEndpoints_getEventById",
      params: {
        id,
      },
      ...data,
    });
  }

  async getReferralTasks(wallet: string) {
    return this.webSocketClient.request<any>({
      method: "eventEndpoints_getReferralTasks",
      params: {
        wallet,
      },
      ...data,
    });
  }
  async claimReferralReward(wallet: string, taskId: number) {
    return this.webSocketClient.request<any>({
      method: "eventEndpoints_claimReferralReward",
      params: {
        wallet,
        taskId,
      },
      ...data,
    });
  }
}

const eventsApi = new EventsApi();
export default eventsApi;

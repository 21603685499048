import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import LoadingSpinner from "../../../../components/LoaderSpinner";
import ErrorMessage from "../../../../components/ErrorMessage";

export const TypesList = ({
  types,
  typesStatus,
  error,
  selectedTypesId,
  handleTypeClick,
}: {
  types: any[];
  typesStatus: string;
  error: string | null;
  selectedTypesId: number | null;
  handleTypeClick: (typeId: number) => void;
}) => {
  let content;

  if (typesStatus === "loading") {
    content = <LoadingSpinner />;
  } else if (typesStatus === "succeeded") {
    content = types.map((type) => (
      <SwiperSlide key={type.id} className="!w-auto select-none">
        <button
          key={type.id}
          className={`flex flex-grow cursor-pointer px-6 py-2 text-xs font-medium text-black items-center justify-center  ${
            selectedTypesId === type.id
              ? "bg-white rounded-md shadow-md font-semibold"
              : ""
          }`}
          onClick={() => handleTypeClick(type.id)}
        >
          {type.name}
        </button>
      </SwiperSlide>
    ));
  } else {
    content = <ErrorMessage error={error} />;
  }

  return (
    <section className="pb-[10px]">
      <Swiper
        centerInsufficientSlides
        slidesPerView={"auto"}
        spaceBetween={0}
        width={null}
        className="!py-0.5 !px-0.5 bg-swiperBg flex flex-row rounded-lg "
      >
        {content}
      </Swiper>
    </section>
  );
};

import { getHttpEndpoint } from "@orbs-network/ton-access";
import { CHAIN, useTonConnectUI } from "@tonconnect/ui-react";
import TonWeb from "tonweb";

import { useAsyncInitialize } from "./useAsyncInitialize";

export function useTonClient() {
  const [tonConnectUI] = useTonConnectUI();

  let network = tonConnectUI.account?.chain;

  return {
    client: useAsyncInitialize(async () => {
      if (!network) return;

      return new TonWeb(
        new TonWeb.HttpProvider(
          await getHttpEndpoint({
            network: network === CHAIN.MAINNET ? "mainnet" : "testnet",
          })
        )
      );
    }, [network]),
  };
}

import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FavoritesEventsList } from "../containers";

const FavoritesPage = () => {
  const [autoScrollToTop, setAutoScrollToTop] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (autoScrollToTop) {
      window.scrollTo(0, 0); // Fallback to window.scrollTo if needed
      setAutoScrollToTop(false);
    }
  }, [autoScrollToTop, location]);
  return (
    <main>
      <FavoritesEventsList />
    </main>
  );
};
export default FavoritesPage;

import React from "react";

import { PlaceBetFooter, FooterNav } from "./components";

export const Footer: React.FC = () => {
  return (
    <>
      <FooterNav />
      <PlaceBetFooter />
    </>
  );
};

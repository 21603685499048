import { useTelegram } from "../../../../hooks/useTelegram";
import usersApi from "../../../../services/usersApi";
import { Setting } from "./components";

export const Settings = ({
  category,
  onBack,
  updateSetting,
}: {
  category: any;
  onBack: () => void;
  updateSetting: (settings: any[]) => void;
}) => {
  const { userId } = useTelegram();
  return (
    <div className="flex flex-col gap-4 p-4 border border-gray-200 rounded-lg">
      <button
        onClick={onBack}
        className="flex justify-start items-center gap-1  text-base text-blue-500 mb-4 "
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="rotate-180"
        >
          <path
            d="M8.3999 3.6L15.5999 12L8.3999 20.4"
            stroke="#28303F"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div>Back to Categories</div>
      </button>
      <div className="flex flex-col gap-2">
        {category.settings.map((setting: any, index: number) => (
          <Setting
            key={index}
            name={setting.name}
            checked={setting.checked}
            onChange={async (newChecked) => {
              const updatedCategorySettings = category.settings.map((s: any) =>
                s.name === setting.name ? { ...s, checked: newChecked } : s
              );

              // Оновлюємо стан на фронтенді
              updateSetting([
                { ...category, settings: updatedCategorySettings },
              ]);

              try {
                // Надсилаємо запит на бекенд
                await usersApi.updateUserSettings(userId ?? "", {
                  [setting.name === "Claim"
                    ? "isAgreeToGetClaimNotification"
                    : setting.name === "Match end"
                    ? "isAgreeToGetEventEndNotification"
                    : "isAgreeToGetReferralBonusNotification"]: newChecked,
                });
              } catch (error) {
                console.error("Failed to update user settings:", error);

                // Показуємо повідомлення про помилку користувачу
                alert("Failed to update setting. Please try again.");

                // Робимо відкат зміни в інтерфейсі
                const revertedSettings = category.settings.map((s: any) =>
                  s.name === setting.name ? { ...s, checked: !newChecked } : s
                );
                updateSetting([{ ...category, settings: revertedSettings }]);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

import { useContext, useEffect } from "react";

import SimpleKeyboard from "react-simple-keyboard";
import useBetData from "../../useBetData";

export const KeyboardComponent = ({
  layout,
  display,
  onChange,
  keyboardRef,
}: any) => {
  return (
    <div className="mb-2">
      <SimpleKeyboard
        layout={layout}
        display={display}
        onChange={onChange}
        keyboardRef={keyboardRef}
        theme={"hg-theme-default my-custom-keyboard"}
      />
    </div>
  );
};

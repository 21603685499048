import React from "react";

import useEventTimer from "./useEventTimer";
import {
  EventStatus,
  IEvent,
  Pool,
} from "../../../../../../app/features/events/eventsSlice";
import {
  EventInfo,
  BookmakerInfo,
  BetButtons,
  Timer,
  NavBar,
} from "./components";
import { Bookmaker } from "../../../../../../interfaces/events.interface";

export const EventItem = ({
  event,
  handleFavorite,
  favoritesDiv,
}: {
  event: IEvent;
  handleFavorite: (id: number) => void;
  favoritesDiv: number[];
}) => {
  const jsonData = JSON.parse(event.bookmakerKoef);
  const bookmakerData: Bookmaker[] = jsonData
    .filter((item: any) => item !== null)
    .map((item: Bookmaker) => item);

  const sortedPools = event.poolInfo.slice().sort((a, b) => a.id - b.id);

  const drawPoolIndex = sortedPools.findIndex(
    (pool) => pool.name.toLowerCase() === "draw"
  );

  if (drawPoolIndex !== -1) {
    const drawPool = sortedPools.splice(drawPoolIndex, 1)[0];
    const middleIndex = Math.floor(sortedPools.length / 2);
    sortedPools.splice(middleIndex, 0, drawPool);
  }

  return (
    <div className="border border-eventBorder w-auto h-auto rounded-lg p-2 bg-white mb-2">
      <EventInfo
        event={event}
        handleFavorite={handleFavorite}
        favoritesDiv={favoritesDiv}
      />
      {/* {event.poolInfo &&
        event.poolInfo.length >= 2 &&
        event.poolInfo.length <= 3 && (
          <div className="flex w-full justify-between items-center text-black mb-1">
            {event.poolInfo
              .filter((pool) => pool.teamName && pool.teamName !== "draw")
              .sort((a, b) => a.id - b.id)
              .map((pool: Pool, i: number) => (
                <div
                  key={i}
                  className={`flex items-center w-2/5 ${
                    i === 0 ? "" : "justify-end"
                  }`}
                >
                  {pool.teamImage ? (
                    <div className="flex bg-teamImageBg h-7 w-7 min-w-7 justify-center items-center rounded-md">
                      <img
                        className="h-5 w-5"
                        src={pool.teamImage}
                        alt={pool.name}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <span
                    className={`ml-2 font-medium text-sm leading-tight ${
                      i === 0 ? "" : "text-right"
                    }`}
                  >
                    {pool.teamName}
                  </span>
                </div>
              ))}
          </div>
        )} */}
      <Timer id={event.id} status={event.status} startDate={event.startDate} />
      {/* <div className="text-center mb-1 leading-none">
        <span className="text-xs text-customGray">
          {event.status === EventStatus.started ? (
            "Odds fixed"
          ) : isRunning ? (
            <div>
              <div>
                {"Event will start in "}
                {formatTime(days, hours, minutes, seconds)}
              </div>
              {new Date(event.startDate).toLocaleString()}
            </div>
          ) : (
            "Odds fixed"
          )}
        </span>
      </div> */}
      {/* {bookmakerData.length > 0 && (
        <BookmakerInfo bookmakerData={bookmakerData} />
      )} */}
      <BetButtons event={event} sortedPools={sortedPools} />
      <NavBar sortedPools={sortedPools} totalSum={event.totalSum} />
    </div>
  );
};

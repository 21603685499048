import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const InfoTooltip = ({ tooltipText }: { tooltipText: string }) => {
  const uniqueId = `tooltip-${Math.random().toString(36).substring(2, 9)}`;

  return (
    <div className=" w-4 h-4 flex justify-center items-center rounded-full bg-blue-600">
      <span className="text-white cursor-pointer" data-tooltip-id={uniqueId}>
        ?
      </span>
      <ReactTooltip
        id={uniqueId}
        place="top"
        content={tooltipText}
        opacity={1}
        border="1px solid #87a6bb"
        style={{
          backgroundColor: "#ffffff", // Повністю непрозорий білий фон
          color: "black",
          padding: "12px",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          maxWidth: "200px",
          textAlign: "center",
          whiteSpace: "normal",
          zIndex: 10,
          fontSize: "13px"
        }}
      />
    </div>
  );
};

export default InfoTooltip;

import { useEffect, useState } from "react";
import { ClaimButton } from "../ClaimButton";
import bonusesApi from "../../services/bonusesApi";
import { useTonAddress } from "@tonconnect/ui-react";
import { fetchBalance } from "../../app/features/currency/currencySlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import InfoTooltip from "../InfoTooltip";

export const Percentages = () => {
  const address = useTonAddress();
  const [loading, setLoading] = useState(false);
  const [rewardSum, setRewardSum] = useState<number | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const fetchRewardSum = async () => {
    setLoading(true);
    try {
      if (address) {
        console.log("here address");
        const result = await bonusesApi.getNotClaimedRewardSum(address);

        setRewardSum(result.totalSum);
      }
    } catch (error) {
      console.error("Error claiming rewards:", error);
    } finally {
      setLoading(false);
    }
  };

  const claimAllSum = async () => {
    if (!rewardSum || (rewardSum && rewardSum <= 0)) return;

    setLoading(true);
    try {
      if (address) {
        const result = await bonusesApi.claimAllSum(address);
        if (result) {
          fetchRewardSum();
          dispatch(fetchBalance(address));
        }
      }
      // Handle the claimed reward sum as needed
    } catch (error) {
      console.error("Error claiming rewards:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRewardSum();
  }, [address, rewardSum]);

  return (
    <div className="flex flex-col pb-8 mb-[70px]">
      <div className="flex justify-between pb-2 items-end">
        <div className="text-black text-base font-bold font-['SF Pro Text'] leading-none tracking-tight">
          Your friends earned
        </div>
        <InfoTooltip
          tooltipText={
            "The sum of all friends' winnings since the last time you claimed the reward"
          }
        />
      </div>

      <div
        className={`flex items-center w-full h-16 ${
          rewardSum ? "bg-green-200" : ""
        } rounded-lg border border-gray-600`}
      >
        <div className="flex flex-grow justify-start pl-4">
          <div
            className={`text-2xl font-extrabold font-['SF Pro Text'] leading-normal tracking-tight ${
              rewardSum ? "text-green-800" : "text-zinc-400"
            }`}
          >
            {rewardSum
              ? `${rewardSum} BETCOINS`
              : " Invite friends to get interest"}
          </div>
        </div>
        {rewardSum ? (
          <div className="flex flex-grow-0 pr-2">
            <ClaimButton
              onClick={claimAllSum}
              eligibleClaim={!!rewardSum}
              loading={loading}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

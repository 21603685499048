import { Router } from "../Router";
import { store } from "../../app/store";
import { Provider } from "react-redux";
import "../../App.css";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { useTelegram } from "../../hooks/useTelegram";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { useEffect } from "react";

export const App = () => {
  const { tg } = useTelegram();

  useEffect(() => {
    if (tg) {
      tg.setHeaderColor("#FFFFFF");
    }
    amplitude.init("456eb55da28b41f17d2b885429cbd290");
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 0.1,
    });
    amplitude.add(sessionReplayTracking);
  }, [tg]);

  return (
    <Provider store={store}>
      <TonConnectUIProvider manifestUrl="https://tgbets-de02f.web.app/manifest.json">
        <div className="App">
          <Router />
        </div>
      </TonConnectUIProvider>
    </Provider>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ResponseData } from "../../../interfaces/responseData";
import transactionApi from "../../../services/transactionApi";

export enum TransactionType {
  in,
  out,
}

export enum TransactionReason {
  bet,
  won,
  jettonAccrual,
  referralReward,
}

interface extraDetails {
  eventName?: string;
  matchName?: string;
}

export interface Transaction extends ResponseData {
  id: number;
  type: TransactionType;
  reason: TransactionReason;
  amount: number;
  currency: number;
  wallet: number;
  cratedAt: string;
  extraDetails: extraDetails;
}

interface TransactionsState {
  transactions: Transaction[];
  status: string;
  error: string | null;
  transactionsCurrentPage: number;
  hasNextPage: boolean;
  transactionsPerPage: number;
}
const initialState: TransactionsState = {
  transactions: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  transactionsCurrentPage: 1,
  hasNextPage: true,
  transactionsPerPage: 10,
};

export const fetchUserTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async (arg: { page: number; perPage: number; wallet: string }) => {
    const { page, perPage, wallet } = arg;
    return transactionApi.getAllTransactionsByWallet(page, perPage, wallet);
  }
);

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setTransactionsCurrentPage: (state, action) => {
      state.transactionsCurrentPage = action.payload;
    },
    setTransactionsPerPage: (state, action) => {
      state.transactionsPerPage = action.payload;
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserTransactions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserTransactions.fulfilled, (state, action) => {
        const totalPages = Math.ceil(
          action.payload.totalCount / state.transactionsPerPage
        );
        state.status = "succeeded";
        state.hasNextPage = state.transactionsCurrentPage < totalPages;
        state.transactionsCurrentPage += 1;
        state.transactions = [...state.transactions, ...action.payload.data];
      })
      .addCase(fetchUserTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const selectAllTransactions = (state: {
  transactions: TransactionsState;
}) => state.transactions.transactions;

export const getTransactionsStatus = (state: {
  transactions: TransactionsState;
}) => state.transactions.status;

export const getTransactionsError = (state: {
  transactions: TransactionsState;
}) => state.transactions.error;

export const selectHasNextPage = (state: { transactions: TransactionsState }) =>
  state.transactions.hasNextPage;

export const selectCurrentPage = (state: { transactions: TransactionsState }) =>
  state.transactions.transactionsCurrentPage;
export const selectPerPage = (state: { transactions: TransactionsState }) =>
  state.transactions.transactionsPerPage;

export const {
  setTransactionsCurrentPage,
  setTransactionsPerPage,
  setTransactions,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;

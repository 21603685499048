import React from "react";

export const BetInput = ({
  value,
  handleChangeInput,
  isLowBalance,
  isNotMinBet,
  isNotMaxBet,
  isValid,
  isValidNumber,
  IsEventStart,
  EventStatus,
  MAX_BET,
  MIN_BET,
  selectedCurrencyId,
  CURRENCY,
  toggleCurrencyList,
  isCurrencyListVisible,
  renderedCurrencies,
  balance,
  currencyName,
  isMobile,
  setShowKeyboard,
}: any) => {
  return (
    <div className="mb-4">
      <div className="rounded-md shadow-sm relative ">
        <input
          readOnly={isMobile}
          onFocus={() => setShowKeyboard(true)}
          type="number"
          value={value}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E") {
              e.preventDefault();
            }
          }}
          className={`block border w-full rounded-lg outline-none no-arrows min-h-minHeightField ${
            isLowBalance ||
            isNotMinBet ||
            isNotMaxBet ||
            !isValid ||
            !isValidNumber ||
            (IsEventStart !== undefined &&
              IsEventStart !== EventStatus.notStarted)
              ? "border-borderError"
              : "focus:border-borderFocus"
          } py-3.5 pl-4 pr-24 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400 bg-bgBetInput text-xl leading-none font-semibold relative `}
          placeholder="Amount"
          onChange={handleChangeInput}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
          <div className="flex">
            <div className="flex gap-1 items-center">
              <div className="w-5 h-5">
                <img
                  className="w-full h-full"
                  src={`./currency/${CURRENCY[selectedCurrencyId]}.svg`}
                  alt={CURRENCY[selectedCurrencyId]}
                />
              </div>
              {currencyName.toUpperCase()}
            </div>
            <button
              onClick={toggleCurrencyList}
              className="p-2 rounded-lg flex gap-1 items-center"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`transition-transform duration-200 ${
                  isCurrencyListVisible ? "" : "transform rotate-180"
                }`}
              >
                <path
                  d="M7 14L12 9L17 14"
                  stroke="#4E68A1"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>

        {isCurrencyListVisible && (
          <div className="absolute right-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 border border-blue-500">
            <div className="p-1 flex flex-col gap-1">{renderedCurrencies}</div>
          </div>
        )}
      </div>

      <div className="flex justify-between">
        <div className="flex items-end gap-x-1">
          <div className="mt-1 text-xs font-medium text-gray-500">Balance:</div>
          <div className="mt-1 text-xs font-bold text-black">
            {balance} {currencyName}
          </div>
        </div>
        {balance !== null && value?.toString().length && (
          <div className="mt-1 text-xs font-medium text-red-500">
            {value < MIN_BET
              ? `Min bet ${MIN_BET} ${currencyName.toUpperCase()}`
              : value > MAX_BET
              ? `Max bet ${MAX_BET} ${currencyName.toUpperCase()}`
              : value > balance
              ? `Not enough funds`
              : !isValidNumber
              ? "Number invalide"
              : ""}
          </div>
        )}
      </div>
    </div>
  );
};

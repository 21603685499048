import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHasNextPage,
  selectCurrentPage,
  selectPerPage,
} from "../../app/features/events/eventsSlice";
import {
  selectAllFavoritesEventsId,
  selectAllFavoritesEvents,
  getFavoritesEventsStatus,
  getFavoritesEventsError,
  deleteEventFromFavorites,
  addEventToFavorites,
  fetchAllFavoritesIds,
  setFavoritesEventsCurrentPage,
  setFavoritesEvents,
  fetchAllFavorites,
} from "../../app/features/events/favoritesEventsSlice";
import { AppDispatch } from "../../app/store";
import ErrorMessage from "../../components/ErrorMessage";
import LoadingSpinner from "../../components/LoaderSpinner";
import NoActivityPage from "../../components/NoActivityPage";
import ScrollHandler from "../../components/ScrollHandler";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import { useTelegram } from "../../hooks/useTelegram";
import favoritesEventsApi from "../../services/favoritesEventsApi";
import { EventItem } from "../Events/components/EventsList/components";

export const FavoritesEventsList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const favoritesId = useSelector(selectAllFavoritesEventsId);
  const favorites = useSelector(selectAllFavoritesEvents);
  const favoritesStatus = useSelector(getFavoritesEventsStatus);
  const error = useSelector(getFavoritesEventsError);
  const hasNextPage = useSelector(selectHasNextPage);
  const currentPage = useSelector(selectCurrentPage);
  const perPage = useSelector(selectPerPage);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { user } = useTelegram();
  const handleFavorite = async (id: number) => {
    try {
      // Отримуємо дані з сервера
      const response = await favoritesEventsApi.getFavoritesById(user.id, id);
      // const response = await favoritesEventsApi.getFavoritesById(
      //   "596621527",
      //   id
      // );

      // Перевіряємо, чи є подія у фейворітах і відповідно додаємо або видаляємо її
      if (response) {
        dispatch(
          deleteEventFromFavorites({ clientId: user.id, eventId: id })
          // deleteEventFromFavorites({ clientId: "596621527", eventId: id })
        );
      } else {
        dispatch(addEventToFavorites({ clientId: user.id, eventId: id }));
        // dispatch(addEventToFavorites({ clientId: "596621527", eventId: id }));
      }
    } catch (error) {
      console.error("Error occurred while handling favorite:", error);
      // Обробляємо помилку, якщо є
    }
  };

  useEffect(() => {
    try {
      dispatch(fetchAllFavoritesIds(user.id));
      dispatch(setFavoritesEventsCurrentPage(1));
      dispatch(setFavoritesEvents([]));
      dispatch(fetchAllFavorites({ clientId: user.id, page: 1, perPage }));
      // dispatch(fetchAllFavorites({ clientId: "596621527", page: 1, perPage }));
    } catch (error) {}
  }, [dispatch]);

  let previousScrollY = useRef(0);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > 300) {
      if (currentScrollY > previousScrollY.current) {
        // Користувач прокручує вниз

        setShowScrollButton(false);
      } else if (currentScrollY < previousScrollY.current) {
        // Користувач прокручує вгору
        setShowScrollButton(true);
      }
    } else {
      setShowScrollButton(false);
    }
    previousScrollY.current = currentScrollY;
    if (favoritesStatus === "loading") {
      return <LoadingSpinner />;
    }
    if (
      window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 50 ||
      favoritesStatus === "loading"
    ) {
      return;
    }
    {
      if (hasNextPage && favoritesStatus !== "loading") {
        try {
          // dispatch(
          //   fetchAllFavorites({
          //     page: currentPage,
          //     perPage,
          //     clientId: "596621527",
          //   })
          // );
          dispatch(
            fetchAllFavorites({
              page: currentPage,
              perPage,
              clientId: user.id,
            })
          );
        } catch (error) {}
      }
    }
  };

  const renderedEvents = favorites.map((event, i) => (
    <EventItem
      key={i}
      event={event}
      handleFavorite={handleFavorite}
      favoritesDiv={favoritesId}
    />
  ));
  let content =
    favoritesStatus === "failed" ? (
      <ErrorMessage error={error} />
    ) : favoritesStatus === "loading" && renderedEvents.length < 1 ? (
      <LoadingSpinner />
    ) : renderedEvents.length ? (
      <>
        <ScrollHandler onScroll={handleScroll} />
        <ScrollToTopButton
          showScrollButton={showScrollButton}
          onClick={scrollToTop}
        />
        {renderedEvents}
        {favoritesStatus === "loading" ? <LoadingSpinner /> : null}
      </>
    ) : (
      <NoActivityPage text={"No events yet"} />
    );
  return <section className="mx-3 my-5">{content}</section>;
};


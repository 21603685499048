import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BetType } from "./betTypeSlice";
import { ResponseData } from "../../../interfaces/responseData";
import betsApi from "../../../services/betsApi";

interface BetPool {
  name: string;
  teamName?: string;
  teamImage?: string;
  totalSum: number;
  isWin?: boolean;
}
interface BetEvent {
  name: string;
  matchName?: string;
  totalSum: number;
}

export interface Bet extends ResponseData {
  id: number;
  value: number;
  pool: BetPool;
  event: BetEvent;
}
interface BetState {
  bets: Bet[];
  status: string;
  error: string | null;
  betsCurrentPage: number;
  hasNextPage: boolean;
  betsPerPage: number;
}

const initialState: BetState = {
  bets: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  betsCurrentPage: 1,
  hasNextPage: true,
  betsPerPage: 10,
};

export const fetchBets = createAsyncThunk(
  "bets/fetchBets",
  async (arg: {
    page: number;
    perPage: number;
    clientId: string;
    status: number;
  }) => {
    const { page, perPage, clientId, status } = arg;
    return betsApi.getAllBets(page, perPage, clientId, status);
  }
);

const betsSlice = createSlice({
  name: "bets",
  initialState,
  reducers: {
    setStatusBet: (state, action) => {
      state.status = action.payload;
    },
    setBetsCurrentPage: (state, action) => {
      state.betsCurrentPage = action.payload;
    },
    setBetsPerPage: (state, action) => {
      state.betsPerPage = action.payload;
    },
    setBets: (state, action) => {
      state.bets = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBets.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchBets.fulfilled, (state, action) => {
        const totalPages = Math.ceil(
          action.payload.totalCount / state.betsPerPage
        );
        state.status = "succeeded";
        state.hasNextPage = state.betsCurrentPage < totalPages;
        state.betsCurrentPage += 1;

        state.bets = [...state.bets, ...action.payload.data];
      })
      .addCase(fetchBets.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const selectAllBets = (state: { bets: BetState }) => state.bets.bets;

export const getBetsStatus = (state: { bets: BetState }) => state.bets.status;

export const getBetsError = (state: { bets: BetState }) => state.bets.error;
export const selectHasNextPage = (state: { bets: BetState }) =>
  state.bets.hasNextPage;

export const selectPerPage = (state: { bets: BetState }) =>
  state.bets.betsPerPage;
export const selectBetsCurrentPage = (state: { bets: BetState }) =>
  state.bets.betsCurrentPage;
export const selectBetById = (state: { bets: BetState }, betId: number) =>
  state.bets.bets.find((bet) => bet.id === betId);

export const { setStatusBet, setBetsCurrentPage, setBetsPerPage, setBets } =
  betsSlice.actions;
export default betsSlice.reducer;

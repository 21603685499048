import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllTypes,
  getTypesStatus,
  getTypesError,
  fetchTypes,
  setTypes,
} from "../../app/features/categories/typesSlice";
import { AppDispatch, RootState } from "../../app/store";

const useTypesData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const types = useSelector(selectAllTypes);
  const typesStatus = useSelector(getTypesStatus);
  const error = useSelector(getTypesError);
  const { selectedTypesId } = useSelector((state: RootState) => state.types);

  useEffect(() => {
    if (typesStatus === "idle" || typesStatus === "failed") {
      dispatch(fetchTypes());
    }
  }, [typesStatus, dispatch]);

  const handleTypeClick = (typeId: number) => {
    dispatch(setTypes(typeId));
  };

  return { types, typesStatus, error, selectedTypesId, handleTypeClick };
};

export default useTypesData;

import { Key } from "react";
import { Transaction } from "../../../../app/features/transactions/transactionsSlice";
import ErrorMessage from "../../../../components/ErrorMessage";
import LoadingSpinner from "../../../../components/LoaderSpinner";
import NoActivityPage from "../../../../components/NoActivityPage";
import ScrollHandler from "../../../../components/ScrollHandler";
import ScrollToTopButton from "../../../../components/ScrollToTopButton";
import { TransactionItem } from "./components";

export const TransactionsList = ({
  transactions,
  transactionsStatus,
  error,
  handleScroll,
  showScrollButton,
  scrollToTop,
}: {
  transactions: any;
  transactionsStatus: any;
  error: any;
  handleScroll: any;
  showScrollButton: any;
  scrollToTop: any;
}) => {
  const renderedTransactions = transactions.map(
    (transaction: Transaction, i: Key | null | undefined) => (
      <TransactionItem key={i} transaction={transaction} />
    )
  );

  let content =
    transactionsStatus === "failed" ? (
      <ErrorMessage error={error} />
    ) : transactionsStatus === "loading" && renderedTransactions.length < 1 ? (
      <LoadingSpinner />
    ) : renderedTransactions.length ? (
      <>
        <ScrollHandler onScroll={handleScroll} />
        <ScrollToTopButton
          showScrollButton={showScrollButton}
          onClick={scrollToTop}
        />
        {renderedTransactions}
        {transactionsStatus === "loading" ? <LoadingSpinner /> : null}
      </>
    ) : (
      <NoActivityPage text={"No activities yet"} />
    );

  return <>{content}</>;
};

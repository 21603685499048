import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTonAddress, useTonConnectUI, CHAIN } from "@tonconnect/ui-react";
import {
  selectAllCurrency,
  getCurrencyStatus,
  getCurrencyError,
  fetchBalance,
  CURRENCY,
  setCurrency,
  decrementBalance,
} from "../../../../../app/features/currency/currencySlice";
import {
  selectIsEventStart,
  selectEventById,
  addBet,
  EventStatus,
} from "../../../../../app/features/events/eventsSlice";
import { selectFavoriteEventById } from "../../../../../app/features/events/favoritesEventsSlice";
import { setActiveBet } from "../../../../../app/features/bet/preBetSlice";
import { AppDispatch, RootState } from "../../../../../app/store";
import { useTelegram } from "../../../../../hooks/useTelegram";
import { useTonClient } from "../../../../../hooks/useTonClient";
import { useSendBetTransaction } from "../../../../../hooks/useSendBetTransaction";
import TonWeb from "tonweb";
import * as amplitude from "@amplitude/analytics-browser";

const { Address } = TonWeb.utils;
export const sleep = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

const useBetData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [value, setValue] = useState<number | undefined>();
  const address = useTonAddress();

  const { user } = useTelegram();
  const { sendTransaction } = useSendBetTransaction();
  const { preBet, activeBet } = useSelector((state: RootState) => state.preBet);
  const { tonBalance } = useSelector((state: RootState) => state.wallet);
  const [isLoading, setIsLoading] = useState(false);
  const { client } = useTonClient();
  const [tonConnectUI] = useTonConnectUI();
  const currenciesData = useSelector(selectAllCurrency);
  const currencyStatus = useSelector(getCurrencyStatus);
  const error = useSelector(getCurrencyError);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [Mobile, setMobile] = useState("");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    dispatch(fetchBalance(address));
  }, [dispatch, address]);

  let currencies = currenciesData.filter(
    (currencyData) => currencyData.currency !== CURRENCY.bbetcoin
  );

  const { selectedCurrencyId } = useSelector(
    (state: RootState) => state.currency
  );

  const IsEventStart = useSelector((state: RootState) =>
    selectIsEventStart(state, preBet.eventId)
  );

  const getCurrencyName = (id: number) => {
    if (!currencies.length) {
      return "";
    }
    const currency = currencies.find((c) => c.currency === id);
    return currency ? CURRENCY[currency.currency] : "";
  };

  const getCurrencyBalance = (id: number) => {
    if (!currencies.length) {
      return 0;
    }

    // Знаходимо потрібну валюту
    const currency = currencies.find((c) => c.currency === id);

    // Додаємо суму bbetcoin, якщо валюта співпадає
    if (currency?.currency === CURRENCY.betcoin) {
      const bbetcoinAmount =
        currenciesData.find((c) => c.currency === CURRENCY.bbetcoin)?.amount ||
        0;

      return (currency.amount || 0) + bbetcoinAmount;
    }

    // Якщо це валюта ton, повертаємо відповідний баланс, інакше просто amount
    return currency?.currency === CURRENCY.ton
      ? tonBalance
      : currency?.amount || 0;
  };

  const chain = tonConnectUI.account?.chain;
  const balance = getCurrencyBalance(selectedCurrencyId);
  console.log(selectedCurrencyId);
  console.log(balance);
  const currencyName = getCurrencyName(selectedCurrencyId);
  const MIN_BET = 1;
  const MAX_BET = 500;
  const isLowBalance = value && balance !== null && value > balance;
  const isNotMinBet = value && value < MIN_BET;
  const isNotMaxBet = value && value > MAX_BET;
  const isValid =
    (typeof value !== "undefined" && (+value?.toString() !== 0 || value)) ||
    typeof value === "undefined";

  const isValidNumber =
    (typeof value !== "undefined" && !value.toString().startsWith("0")) ||
    typeof value === "undefined";

  const event = useSelector((state: RootState) => {
    const selectedEvent = selectEventById(state, Number(preBet.eventId));
    return (
      selectedEvent || selectFavoriteEventById(state, Number(preBet.eventId))
    );
  });

  const closeBet = (isActive: boolean) => {
    setValue(undefined);
    dispatch(setActiveBet(isActive));
  };

  const handleCurrency = (id: number) => {
    dispatch(setCurrency(id));
  };

  const handleFastBet = (bet: number) => {
    setValue(bet);
  };

  const calculatePotentialWinnings = () => {
    if (value && value > 0 && value >= MIN_BET) {
      return (value * preBet.coefficient).toFixed(2);
    } else {
      return (0 * preBet.coefficient).toFixed(2);
    }
  };

  useEffect(() => {
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent;
      setIsMobile(/android|Android|iPad|iPhone|iPod/.test(userAgent));
      setMobile(userAgent);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  const keyboardOptions = {
    layout: {
      default: ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}"],
    },
    display: {
      "{bksp}": "⌫",
    },
  };

  const handleChangeInput = (event: any) => {
    let inputValue = event.target.value;

    const maxLength = MAX_BET.toString().length;
    const isStringTooLong = inputValue.toString().length > maxLength;

    const hasDecimal = inputValue.includes(".") || inputValue.includes(","); // Перевірка наявності коми/крапки

    if (hasDecimal) {
      const [beforeDecimal, afterDecimal] = inputValue.split(/[,.]/);
      const isBeforeDecimalTooLong =
        beforeDecimal.toString().length > maxLength;
      const isAfterDecimalTooLong = afterDecimal.toString().length > 4;

      if (isBeforeDecimalTooLong) {
        event.target.value = Number(
          `${beforeDecimal.replace(/[0-9]/g, "")}.${afterDecimal}`
        );
      } else if (isAfterDecimalTooLong) {
        event.target.value = Number(
          `${beforeDecimal}.${afterDecimal.replace(/[0-9]/g, "")}`
        );
      } else {
        setValue(inputValue);
      }
    } else if (isStringTooLong) {
      event.target.value = inputValue.replace(/[0-9]/g, ""); // Видаляємо всі цифри
    } else {
      setValue(inputValue);
    }
  };

  const keyboard = useRef<any>();
  const handleChangeKeyboard = (input: string) => {
    const maxLength = MAX_BET.toString().length;
    const isStringTooLong = input.length > maxLength;

    const hasDecimal = input.includes(".") || input.includes(","); // Перевірка наявності коми/крапки

    if (hasDecimal) {
      const [beforeDecimal, afterDecimal] = input.split(/[,.]/);
      const isBeforeDecimalTooLong = beforeDecimal.length > maxLength;
      const isAfterDecimalTooLong = afterDecimal.length > 4;

      if (isBeforeDecimalTooLong) {
        keyboard.current.input.default =
          keyboard.current.input.default.substring(0, maxLength);
      } else if (isAfterDecimalTooLong) {
        keyboard.current.input.default =
          keyboard.current.input.default.substring(0, maxLength);
      } else {
        setValue(+input);
      }
    } else if (isStringTooLong) {
      keyboard.current.input.default = keyboard.current.input.default.substring(
        0,
        maxLength
      );
    } else {
      setValue(+input);
    }
  };

  const placeBet = async (
    value: number,
    eventId: number,
    poolId: number,
    eventWallet: string
  ) => {
    try {
      if (tonConnectUI.account?.chain === CHAIN.MAINNET) {
        const payload = `eventId ${eventId}, poolId ${poolId}`;
        if (selectedCurrencyId !== CURRENCY.ton) {
          dispatch(
            addBet({
              value,
              poolId,
              eventId,
              txHash: `CURRENCY = ${CURRENCY[selectedCurrencyId]}`,
              wallet: address,
              clientId: user.id,
            })
          );
          dispatch(
            decrementBalance({ currencyId: selectedCurrencyId, amount: value })
          );
        }

        if (client && selectedCurrencyId === CURRENCY.ton) {
          const tx = (
            await client.getTransactions(new Address(address), 1, 0)
          )[0];

          const lastTxHash = tx.transaction_id.hash;

          const transaction = await sendTransaction({
            to: eventWallet,
            value,
            payload,
          });

          if (transaction) {
            setIsLoading(true);
          }

          let txHash = lastTxHash;
          let lastTx = tx;

          while (txHash === lastTxHash) {
            await sleep(1500);
            let tx = (
              await client.getTransactions(new Address(address), 1, 0)
            )[0];
            lastTx = tx;
            txHash = tx.transaction_id.hash;
          }
          if (txHash !== lastTxHash && transaction) {
            let outMsg1 = lastTx.out_msgs[0];

            if (outMsg1) {
              const dest = new Address(outMsg1.destination).toString(
                true,
                false,
                false
              );

              const parsedEventWallet = new Address(eventWallet).toString(
                true,
                false,
                false
              );

              if (dest === parsedEventWallet) {
                dispatch(
                  addBet({
                    value,
                    poolId,
                    eventId,
                    txHash,
                    wallet: address,
                    clientId: user.id,
                  })
                );
              }
            }
          }
        }
        // amplitude.track("Place Bet", {
        //   userId: user.id,
        //   value: value,
        //   wallet: address,
        //   eventId: eventId,
        //   currency: CURRENCY[selectedCurrencyId],
        // });
        setValue(undefined);
        setIsLoading(false);
        // dispatch(setActiveBet(false));
        // Запуск відео
        setIsVideoPlaying(true);
        setTimeout(() => {
          setIsVideoPlaying(false);
          dispatch(setActiveBet(false));
        }, 3000);
      } else {
        console.log("Nothing's Gonna Happen");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    showKeyboard,
    setShowKeyboard,
    isVideoPlaying,
    isMobile,
    chain,
    value,
    setValue,
    address,
    preBet,
    activeBet,
    tonBalance,
    currencies,
    currencyStatus,
    error,
    selectedCurrencyId,
    IsEventStart,
    balance,
    currencyName,
    MIN_BET,
    MAX_BET,
    isLowBalance,
    isNotMinBet,
    isNotMaxBet,
    isValid,
    isValidNumber,
    event,
    closeBet,
    handleCurrency,
    handleFastBet,
    calculatePotentialWinnings,
    keyboardOptions,
    handleChangeInput,
    keyboard,
    handleChangeKeyboard,
    placeBet,
    isLoading,
    setIsLoading,
  };
};

export default useBetData;

import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectCategoryById } from "../../../../../../../../app/features/categories/categoriesSlice";
import { selectTypeById } from "../../../../../../../../app/features/categories/typesSlice";
import { IEvent } from "../../../../../../../../app/features/events/eventsSlice";
import { RootState } from "../../../../../../../../app/store";

export const EventInfo = ({
  event,
  handleFavorite,
  favoritesDiv,
}: {
  event: IEvent;
  handleFavorite: (id: number) => void;
  favoritesDiv: number[];
}) => {
  const category = useSelector((state: RootState) =>
    selectCategoryById(state, event.categoryId)
  );
  const type = useSelector((state: RootState) =>
    selectTypeById(state, event.typeId)
  );

  return (
    <div className="flex items-center justify-between mb-4 gap-3">
      <div className="flex items-center space-x-1">
        {category ? (
          <img
            src={`./${type?.name.toLowerCase()}/${category.name
              .toLowerCase()
              .split(" ")
              .join("")}.svg`}
            className="w-4 h-4"
            alt={event.name}
          />
        ) : (
          ""
        )}
        <span className="text-xs text-customGray">{event.name}</span>
      </div>
      <div className="flex items-center space-x-1">
        <div
          className={`flex items-center space-x-2 rounded-full ${
            favoritesDiv.includes(event.id) ? "bg-slate-500" : "bg-gray-200"
          } p-1 cursor-pointer`}
          onClick={() => handleFavorite(event.id)}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 7.19583L6.72917 8.24167C7.04583 8.43333 7.43333 8.15 7.35 7.79167L6.89167 5.825L8.42083 4.5C8.7 4.25833 8.55 3.8 8.18333 3.77083L6.17083 3.6L5.38333 1.74167C5.24167 1.40417 4.75833 1.40417 4.61667 1.74167L3.82917 3.59583L1.81667 3.76667C1.45 3.79583 1.3 4.25417 1.57917 4.49583L3.10833 5.82083L2.65 7.7875C2.56667 8.14583 2.95417 8.42917 3.27083 8.2375L5 7.19583Z"
              fill={favoritesDiv.includes(event.id) ? "white" : "#87A6BB"}
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

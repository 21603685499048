import { Pool } from "../../../../../../../../app/features/events/eventsSlice";

export const NavBar = ({
  sortedPools,
  totalSum,
}: {
  sortedPools: Pool[];
  totalSum: number;
}) => {
  return (
    <div className="flex justify-between items-center overflow-hidden rounded-lg text-xxs text-center text-black">
      {sortedPools.map((pool: Pool, i: number) => {
        let percentage =
          pool.totalSum > 0
            ? ((pool.totalSum / totalSum) * 100).toFixed(1)
            : totalSum > 0
            ? 0
            : 50;
        return (
          <div
            key={i}
            className={`${
              [
                "bg-green-300",
                "bg-blue-300",
                "bg-red-300",
                "bg-gray-300",
                "bg-yellow-300",
                "bg-purple-300",
                "bg-gray-700",
                "bg-brown-300",
              ][i]
            }`}
            style={{ width: `${percentage}%` }}
          >
            {+percentage > 5 ? `${percentage}%` : `${percentage} `}
          </div>
        );
      })}
    </div>
  );
};

import { useTonAddress } from "@tonconnect/ui-react";
import { useTelegram } from "../../hooks/useTelegram";

export const InviteButton = () => {
  const { tg } = useTelegram();
  const address = useTonAddress();

  const handleInviteClick = () => {
    if (!address) return;
    const url = `https://t.me/BET_TON_APP_BOT/get_free_bets?startapp=${address}`;

    const text = `Use this link to get 500 bonus BETCOIN for both of you and your friend`;
    if (tg) {
      tg.openTelegramLink(`https://t.me/share/url?url=${url}&text=${text}`);
    }
  };

  return (
    <div
      className="select-none cursor-pointer min-w-[72px] h-12 pb-1 bg-sky-950 rounded-md shadow-lg border border-black relative transform transition-transform duration-150 active:scale-95"
      onClick={handleInviteClick}
    >
      <div className="relative inline-flex items-center justify-center w-full h-full bg-gradient-to-b from-blue-600 to-sky-800 rounded-md ">
        <div className="absolute inset-0 rounded-md bg-gradient-to-b from-transparent to-black opacity-20"></div>
        <div className="relative">
          <span className="relative text-white text-base font-extrabold font-['SF Pro Text'] leading-none tracking-wider text-stroke-3">
            Invite
          </span>
        </div>
      </div>
      <div
        className="absolute inset-0 rounded-md border-b-2 border-black"
        style={{ filter: "blur(2px)" }}
      ></div>
    </div>
  );
};

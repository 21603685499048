import { createSlice } from "@reduxjs/toolkit";

interface CurrentPageState {
  currentPage: string;
}
const initialState: CurrentPageState = {
  currentPage: "/",
};

const currentPageSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      return {
        ...state,
        currentPage: action.payload,
      };
    },
  },
});
export const { setCurrentPage } = currentPageSlice.actions;
export default currentPageSlice.reducer;


export const IncomeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 18V5" stroke="#00AC78" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M8 15L12 19L16 15"
      stroke="#00AC78"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ExpenseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6L12 19"
      stroke="#DF454A"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16 9L12 5L8 9"
      stroke="#DF454A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { useEffect, useState } from "react";
import { Settings } from "./components";
import usersApi from "../../services/usersApi";
import { useTelegram } from "../../hooks/useTelegram";

const settingsArray = [
  {
    name: "Notification",
    settings: [
      { name: "Claim", checked: true },
      { name: "Match end", checked: true },
      { name: "Refferal bonuses", checked: true },
    ],
  },
];

export const SettingsList = () => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const { userId } = useTelegram();
  const [settings, setSettings] = useState<any[]>(settingsArray);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        if (!userId) {
          return;
        }
        const userSettings = await usersApi.getUserSettings(userId);
        setSettings(userSettings);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleCategoryClick = (categoryName: string) => {
    setSelectedCategory((prev) =>
      prev === categoryName ? null : categoryName
    );
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      {selectedCategory === null
        ? settings.map((category, index) => (
            <div
              key={index}
              onClick={() => handleCategoryClick(category.name)}
              className="flex justify-between items-center cursor-pointer border border-[#87a6bb] text-black text-sm font-bold font-['SF Pro Text'] leading-[14px] tracking-tight p-5 bg-[#f5f6fa] rounded-lg hover:bg-gray-300"
            >
              <div>{category.name}</div>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.3999 3.6L15.5999 12L8.3999 20.4"
                  stroke="#28303F"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          ))
        : settings
            .filter((category) => category.name === selectedCategory)
            .map((category, index) => (
              <Settings
                key={index}
                category={category}
                onBack={() => setSelectedCategory(null)}
                updateSetting={(updatedSettings) =>
                  setSettings(updatedSettings)
                }
              />
            ))}
    </div>
  );
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTonAddress } from "@tonconnect/ui-react";
import {
  selectAllCurrency,
  getCurrencyStatus,
  getCurrencyError,
  fetchBalance,
} from "../../app/features/currency/currencySlice";
import { AppDispatch } from "../../app/store";

const useCurrencyData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currencies = useSelector(selectAllCurrency);
  const currencyStatus = useSelector(getCurrencyStatus);
  const error = useSelector(getCurrencyError);
  const address = useTonAddress();

  useEffect(() => {
    if (address) {
      dispatch(fetchBalance(address));
    }
  }, [address, dispatch]);

  return { currencies, currencyStatus, error };
};

export default useCurrencyData;

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { TypesList } from "../containers/EventTypes/components";
import { TypesComponent } from "../containers/EventTypes";
import { Events } from "../containers/Events";
import { Categories } from "../containers/Categories";

const HomePage = () => {
  const [autoScrollToTop, setAutoScrollToTop] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (autoScrollToTop) {
      window.scrollTo(0, 0); // Fallback to window.scrollTo if needed
      setAutoScrollToTop(false);
    }
  }, [autoScrollToTop, location]);
  return (
    <main className="p-4 pb-0">
      <TypesComponent />
      <Categories />
      <Events />
    </main>
  );
};
export default HomePage;

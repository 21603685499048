import { IEvent } from "../app/features/events/eventsSlice";
import { BACKEND_KEYS } from "../common/consts";

import { IUserBet } from "../interfaces/userBet";
import WebSocketClient from "./WebSocketClient";

const data = { agent: "event", lang: "en", auth: { type: "webSite" } };

class PoolApi {
  private webSocketClient: WebSocketClient;

  constructor() {
    this.webSocketClient = WebSocketClient.getInstance(BACKEND_KEYS.SERVER_URL);
  }

  async createBet(userBet: IUserBet) {
    return this.webSocketClient.request<IEvent>({
      method: "eventEndpoints_makeBet",
      params: {
        userWallet: userBet.wallet,
        userClientId: String(userBet.clientId),
        value: +userBet.value,
        poolId: userBet.poolId,
        eventId: userBet.eventId,
        txHash: userBet.txHash,
      },
      ...data,
    });
  }

  async getLastClaimDate(wallet: string) {
    return this.webSocketClient.request<any>({
      method: "eventEndpoints_getLastClaimDate",
      params: {
        wallet,
      },
      ...data,
    });
  }

  async claimPoints(wallet: string) {
    return this.webSocketClient.request<Number>({
      method: "eventEndpoints_claimPoints",
      params: {
        wallet,
      },
      ...data,
    });
  }
}

const poolApi = new PoolApi();
export default poolApi;

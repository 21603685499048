import React from "react";
import useBetCalculations from "./useBetCalculations";
import { Bet } from "../../../../../../app/features/bet/betSlice";
import { BetDetails } from "./components";

export const BetItem = ({ bet }: { bet: Bet }) => {
  const { outcame, win, coefficient, potensialWinning } =
    useBetCalculations(bet);

  return (
    <div className="bg-bgBets border border-eventBorder rounded-lg p-2 my-2">
      <BetDetails
        bet={bet}
        coefficient={coefficient}
        potensialWinning={potensialWinning}
        win={win}
      />
    </div>
  );
};

import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserTransactions,
  getTransactionsError,
  getTransactionsStatus,
  selectAllTransactions,
  selectCurrentPage,
  selectHasNextPage,
  selectPerPage,
  setTransactions,
  setTransactionsCurrentPage,
} from "../../app/features/transactions/transactionsSlice";
import { AppDispatch } from "../../app/store";
import { useTelegram } from "../../hooks/useTelegram";
import { useTonAddress } from "@tonconnect/ui-react";

const useTransactionData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const transactions = useSelector(selectAllTransactions);
  const transactionsStatus = useSelector(getTransactionsStatus);
  const error = useSelector(getTransactionsError);
  const hasNextPage = useSelector(selectHasNextPage);
  const currentPage = useSelector(selectCurrentPage);
  const perPage = useSelector(selectPerPage);
  const { user } = useTelegram();

  const wallet = useTonAddress();

  const setInitialTransactions = () => {
    dispatch(setTransactionsCurrentPage(1));
    dispatch(setTransactions([]));
    dispatch(
      fetchUserTransactions({
        page: 1,
        perPage: perPage,
        wallet,
      })
    );
  };

  return {
    transactions,
    transactionsStatus,
    error,
    hasNextPage,
    currentPage,
    perPage,
    user,
    setInitialTransactions,
  };
};

export default useTransactionData;

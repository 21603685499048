import React from "react";

const ScrollToTopButton = ({
  showScrollButton,
  onClick,
}: {
  showScrollButton: any;
  onClick: any;
}) => {
  return (
    showScrollButton && (
      <button
        className="z-10 fixed bottom-20 right-4 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-full drop-shadow-md"
        onClick={onClick}
      >
        <div className="p-1">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.6666 18.6667L15.9999 12L9.33325 18.6667"
              stroke="white"
              stroke-width="3.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </button>
    )
  );
};

export default ScrollToTopButton;

import React from "react";
import useEventsData from "./useEventsData";
import { EventsList } from "./components";

export const Events = () => {
  const {
    events,
    favoritesId,
    eventStatus,
    error,
    showScrollButton,
    handleFavorite,
    handleScroll,
    scrollToTop,
  } = useEventsData();

  return (
    <EventsList
      events={events}
      favoritesId={favoritesId}
      eventStatus={eventStatus}
      error={error}
      showScrollButton={showScrollButton}
      handleFavorite={handleFavorite}
      handleScroll={handleScroll}
      scrollToTop={scrollToTop}
    />
  );
};

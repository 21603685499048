import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { CHAIN, useTonConnectUI } from "@tonconnect/ui-react";
import {
  PreBet,
  setPreBet,
  setActiveBet,
} from "../../../../../../../../app/features/bet/preBetSlice";
import {
  Pool,
  EventStatus,
  IEvent,
  selectIsEventStart,
} from "../../../../../../../../app/features/events/eventsSlice";
import { AppDispatch, RootState } from "../../../../../../../../app/store";

export const BetButtons = ({
  event,
  sortedPools,
}: {
  event: IEvent;
  sortedPools: Pool[];
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [tonConnectUI] = useTonConnectUI();
  const IsEventStart = useSelector((state: RootState) =>
    selectIsEventStart(state, event.id)
  );

  const handleBet = (isActive: boolean, preBet: PreBet) => {
    dispatch(setPreBet(preBet));
    dispatch(setActiveBet(isActive));
  };

  const margin = 0.04 / event.poolInfo.length;
  const colors = [
    "bg-betGreen",
    "bg-betBlue",
    "bg-betRed",
    "bg-betFabric",
    "bg-betYellow",
    "bg-betPurple",
    "bg-betSilver",
    "bg-betBrown",
    "bg-betGreen",
    "bg-betBlue",
    "bg-betRed",
    "bg-betFabric",
    "bg-betYellow",
    "bg-betPurple",
    "bg-betSilver",
    "bg-betBrown",
    "bg-betGreen",
    "bg-betBlue",
    "bg-betRed",
    "bg-betFabric",
    "bg-betYellow",
    "bg-betPurple",
    "bg-betSilver",
    "bg-betBrown",
    "bg-betGreen",
    "bg-betBlue",
    "bg-betRed",
    "bg-betFabric",
    "bg-betYellow",
    "bg-betPurple",
    "bg-betSilver",
    "bg-betBrown",
  ];

  const colorsFrom = [
    "from-green-50",
    "from-blue-50",
    "from-red-50",
    "from-gray-50",
    "from-yellow-50",
    "from-purple-50",
    "from-gray-300",
    "from-brown-50",
    "from-green-50",
    "from-blue-50",
    "from-red-50",
    "from-gray-50",
    "from-yellow-50",
    "from-purple-50",
    "from-gray-300",
    "from-brown-50",
    // додати інші кольори
  ];

  const colorsTo = [
    "to-green-300",
    "to-blue-300",
    "to-red-300",
    "to-gray-300",
    "to-yellow-300",
    "to-purple-300",
    "to-gray-500",
    "to-brown-300",
    "to-green-300",
    "to-blue-300",
    "to-red-300",
    "to-gray-300",
    "to-yellow-300",
    "to-purple-300",
    "to-gray-500",
    "to-brown-300",
    // додати інші кольори
  ];

  const colorsBorder = [
    "border-green-400",
    "border-blue-400",
    "border-red-400",
    "border-gray-400",
    "border-yellow-400",
    "border-purple-400",
    "border-gray-700",
    "border-brown-400",
    "border-green-400",
    "border-blue-400",
    "border-red-400",
    "border-gray-400",
    "border-yellow-400",
    "border-purple-400",
    "border-gray-700",
    "border-brown-400",
  ];

  // const colorsBorder = [
  //   "border-betBorderGreen",
  //   "border-betBorderBlue",
  //   "border-betBorderRed",
  //   "border-betBorderFabric",
  //   "border-betBorderYellow",
  //   "border-betBorderPurple",
  //   "border-betBorderSilver",
  //   "border-betBorderBrown",
  //   "border-betBorderGreen",
  //   "border-betBorderBlue",
  //   "border-betBorderRed",
  //   "border-betBorderFabric",
  //   "border-betBorderYellow",
  //   "border-betBorderPurple",
  //   "border-betBorderSilver",
  //   "border-betBorderBrown",
  //   "border-betBorderGreen",
  //   "border-betBorderBlue",
  //   "border-betBorderRed",
  //   "border-betBorderFabric",
  //   "border-betBorderYellow",
  //   "border-betBorderPurple",
  //   "border-betBorderSilver",
  //   "border-betBorderBrown",
  //   "border-betBorderGreen",
  //   "border-betBorderBlue",
  //   "border-betBorderRed",
  //   "border-betBorderFabric",
  //   "border-betBorderYellow",
  //   "border-betBorderPurple",
  //   "border-betBorderSilver",
  //   "border-betBorderBrown",
  // ];

  return (
    <div
      className={`w-full h-auto mt-4 mb-2 ${
        event.poolInfo.length < 4 ? "flex flex-row" : "grid grid-cols-2"
      } gap-2 justify-center text-black`}
    >
      {sortedPools.slice().map((pool: Pool, i: number) => {
        let percentage =
          pool.totalSum > 0
            ? ((pool.totalSum / event.totalSum) * 100).toFixed(1)
            : event.totalSum > 0
            ? 0
            : 50;

        return (
          <button
            key={i}
            disabled={
              EventStatus.started === IsEventStart
                ? true
                : tonConnectUI.account?.chain === CHAIN.MAINNET
                ? false
                : true
            }
            className={`bg-gradient-to-b ${colorsFrom[i]} ${colorsTo[i]} rounded-md shadow-inner flex flex-col gap-1 items-center p-2 w-full cursor-pointer select-none border ${colorsBorder[i]}`}
            onClick={() =>
              handleBet(true, {
                eventId: event.id,
                poolId: pool.id,
                coefficient: Number(
                  pool.totalSum > 0
                    ? (
                        (1 / (pool.totalSum / event.totalSum)) *
                        (1 - margin)
                      ).toFixed(2)
                    : event.totalSum > 0
                    ? 0
                    : 1
                ),
              })
            }
          >
            <div className="flex gap-1 items-center">
              {pool.teamImage ? (
                <div className="flex justify-center items-center rounded-md">
                  <img
                    className="h-3 w-3"
                    src={pool.teamImage}
                    alt={pool.name}
                  />
                </div>
              ) : (
                ""
              )}
              <span className="text-center text-black text-xs font-normal font-['SF Pro Text'] leading-3">
                {pool.name}
              </span>
            </div>

            <div className="flex flex-row gap-2 items-center justify-center">
              <span className="text-right text-black text-sm font-extrabold font-['SF Pro Text'] leading-[14px]">
                {pool.totalSum > 0
                  ? (
                      (1 / (pool.totalSum / event.totalSum)) *
                      (1 - margin)
                    ).toFixed(2)
                  : event.totalSum > 0
                  ? 0
                  : 1}
              </span>
              <span className="text-right text-black text-xs font-light font-['SF Pro Text'] leading-3">{`(${percentage}%)`}</span>
            </div>
            <div className="relative bg-white rounded-full p-0.5 pl-1 mt-1">
              <div className="flex items-start ">
                <div className="flex items-center justify-between gap-1 ">
                  <span className="text-xs font-normal text-gray-500 leading-none">
                    {pool.totalSum ? pool.totalSum.toFixed(2) : 0}
                  </span>
                  <div className="flex justify-center items-center w-[18px] h-[18px] rounded-full bg-purple-500">
                    <div className="fltext-center text-white text-[7px] font-bold font-['SF Pro Text'] leading-3 tracking-tight">
                      BETC
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

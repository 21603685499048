import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ResponseData } from "../../../interfaces/responseData";
import { IUserBet } from "../../../interfaces/userBet";
import eventsApi from "../../../services/eventsApi";
import poolApi from "../../../services/poolApi";

export enum EventStatus {
  notStarted,
  started,
  ended,
  completed,
}

export interface Pool {
  id: number;
  name: string;
  teamName?: string;
  teamId?: number;
  totalSum: number;
  teamImage?: string;
}

export interface IEvent extends ResponseData {
  id: number;
  name: string;
  eventImage: string;
  startDate: string;
  poolInfo: Pool[];
  typeId: number;
  categoryId: number;
  totalSum: number;
  eventWallet: string;
  status: EventStatus;
  bookmakerKoef: string;
}

interface EventsState {
  events: IEvent[];
  status: string;
  error: string | null;
  eventsCurrentPage: number;
  hasNextPage: boolean;
  eventsPerPage: number;
}

const initialState: EventsState = {
  events: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  eventsCurrentPage: 1,
  hasNextPage: true,
  eventsPerPage: 10,
};

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async (arg: {
    page: number;
    perPage: number;
    typeId?: number;
    categoryId?: number | null | undefined;
  }) => {
    const { page, perPage, typeId, categoryId } = arg;
    const response = await eventsApi.getAllEvents(
      page,
      perPage,
      typeId,
      categoryId
    );
    return response;
  }
);

export const fetchEventById = createAsyncThunk(
  "events/fetchEventById",
  async (eventId: number) => {
    const response = await eventsApi.getEventById(eventId);
    return response;
  }
);

export const addBet = createAsyncThunk(
  "bets/addBet",
  async (userBet: IUserBet): Promise<IEvent> => {
    return poolApi.createBet(userBet);
  }
);

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setIsEventStatus: (state, action) => {
      const { id, status } = action.payload;

      state.events = state.events.map((event) =>
        event.id === id ? { ...event, status } : event
      );
    },

    setEventsCurrentPage: (state, action) => {
      state.eventsCurrentPage = action.payload;
    },
    setEventsPerPage: (state, action) => {
      state.eventsPerPage = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEvents.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        const totalPages = Math.ceil(
          action.payload.totalCount / state.eventsPerPage
        );
        state.status = "succeeded";
        state.hasNextPage = state.eventsCurrentPage < totalPages;
        state.eventsCurrentPage += 1;

        state.events = [...state.events, ...action.payload.data];
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "An error occurred";
      })
      .addCase(addBet.fulfilled, (state, action) => {
        const { id } = action.payload;

        const events = state.events.map((event) => {
          if (event.id === id) {
            return action.payload;
          }
          return event;
        });
        state.events = events;
      });
  },
});
export const { setIsEventStatus, setEventsCurrentPage, setEvents } =
  eventsSlice.actions;

export const selectAllEvents = (state: { events: EventsState }) =>
  state.events.events;
export const getEventsStatus = (state: { events: EventsState }) =>
  state.events.status;

export const getEventsError = (state: { events: EventsState }) =>
  state.events.error;
export const selectHasNextPage = (state: { events: EventsState }) =>
  state.events.hasNextPage;

export const selectIsEventStart = (
  state: { events: EventsState },
  eventId: number
) => state.events.events.find((event) => event.id === eventId)?.status;

export const selectCurrentPage = (state: { events: EventsState }) =>
  state.events.eventsCurrentPage;

export const selectPerPage = (state: { events: EventsState }) =>
  state.events.eventsPerPage;

export const selectEventById = (
  state: { events: EventsState },
  eventId: number
) => state.events.events.find((event) => event.id === eventId);

export default eventsSlice.reducer;

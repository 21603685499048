import { useTonAddress } from "@tonconnect/ui-react";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const NotFound = () => {
  const [autoScrollToTop, setAutoScrollToTop] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (autoScrollToTop) {
      window.scrollTo(0, 0); // Fallback to window.scrollTo if needed
      setAutoScrollToTop(false);
    }
  }, [autoScrollToTop, location]);
  return (
    <main>
      <div>NotFound</div>
    </main>
  );
};
export default NotFound;

import React, { useEffect, useState } from "react";
import { InviteBonuse } from "./InviteBonuse";
import eventsApi from "../../services/eventsApi";
import { useTonAddress } from "@tonconnect/ui-react";
import { fetchBalance } from "../../app/features/currency/currencySlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import LoaderSpinner from "../LoaderSpinner";

enum TASK {
  common,
  premium,
}

export const InviteBonuses = () => {
  const [loading, setLoading] = useState(false);
  const [referrals, setReferrals] = useState<number | null>(null);
  const [premiumReferrals, setPremiumReferrals] = useState<number | null>(null);
  const [commonRewardSum, setCommonRewardSum] = useState<number | null>(null);
  const [nextCommonReward, setNextcommonReward] = useState<number | null>(null);
  const [nextCommonFriends, setNextcommonFriends] = useState<number | null>(
    null
  );
  const [premiumRewardsSum, setPremiumRewardsSum] = useState<number | null>(
    null
  );
  const [nextPremiumReward, setNextpremiumReward] = useState<number | null>(
    null
  );
  const [nextPremiumFriends, setNextpremiumFriends] = useState<number | null>(
    null
  );

  const dispatch = useDispatch<AppDispatch>();
  const address = useTonAddress();

  const fetchTasks = async () => {
    setLoading(true);
    try {
      if (address) {
        console.log("here address");
        const result = await eventsApi.getReferralTasks(address);

        setReferrals(result.referrals);

        setPremiumReferrals(result.premiumReferrals);

        setCommonRewardSum(result.commonReward);
        setNextcommonReward(result.nextStageCommonReward);
        setNextcommonFriends(result.nextStageCommonFriends);

        setPremiumRewardsSum(result.premiumRewards);
        setNextpremiumReward(result.nextStagePremiumReward);
        setNextpremiumFriends(result.nextStagePremiumFriends);
      }
    } catch (error) {
      console.error("Error claiming rewards:", error);
    } finally {
      setLoading(false);
    }
  };

  const claimReferralReward = async (taskId: number) => {
    setLoading(true);
    try {
      if (address) {
        const result = await eventsApi.claimReferralReward(address, taskId);
        if (result) {
          fetchTasks();
          dispatch(fetchBalance(address));
        }
      }
    } catch (error) {
      console.error("Error claiming rewards:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [address, commonRewardSum, premiumRewardsSum]);

  const tasks = [
    {
      task: nextCommonFriends
        ? `Invite ${nextCommonFriends} friends ${
            nextCommonReward ? `to claim ${nextCommonReward} BETCOIN` : ""
          }`
        : "Invite a Friend",
      taskDescription:
        referrals && nextCommonFriends
          ? `${referrals}/${nextCommonFriends} frens`
          : "Get a bonus when your friend joins.",
      taskImg: "./invitePhoto/invite.svg",
      eligibleClaim: !!commonRewardSum && commonRewardSum > 0,
      taskId: TASK.common,
    },
    {
      task: nextPremiumFriends
        ? `Invite ${nextPremiumFriends} friends with Telegram Premium ${
            nextPremiumReward ? `to claim ${nextPremiumReward} BETCOIN` : ""
          }`
        : "Invite a friend with Telegram Premium",
      taskDescription:
        premiumReferrals && nextPremiumFriends
          ? `${premiumReferrals}/${nextPremiumFriends} frens`
          : "Get a 5x bonus when your friend joins.",
      taskImg: "./invitePhoto/invitePrem.svg",
      eligibleClaim: !!premiumRewardsSum && premiumRewardsSum > 0,
      taskId: TASK.premium,
    },
    {
      taskDescription:
        "When your friend wins - you also get 5% of the winnings",
      taskImg: "./invitePhoto/invitePros.svg",
      status: "inviteLink",
      eligibleClaim: true,
      taskId: TASK.common,
    },
  ];

  return (
    <div className="flex flex-col gap-4 pb-9">
      {loading ? (
        <LoaderSpinner />
      ) : (
        tasks.map((task, index) => (
          <InviteBonuse
            key={index}
            task={task.task}
            taskDescription={task.taskDescription}
            taskImg={task.taskImg}
            eligibleClaim={task.eligibleClaim}
            status={task.status}
            claimReferralReward={() => claimReferralReward(task.taskId)}
          />
        ))
      )}
    </div>
  );
};

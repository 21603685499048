import { createSlice } from "@reduxjs/toolkit";

export interface PreBet {
  eventId: number;
  poolId: number;
  coefficient: number;
  name?: string;
}

interface PreBetState {
  preBet: PreBet;
  activeBet: boolean;
}

const initialState: PreBetState = {
  preBet: {
    eventId: 0,
    poolId: 0,
    coefficient: 0,
  },
  activeBet: false,
};

const preBetsSlice = createSlice({
  name: "preBets",
  initialState,
  reducers: {
    setActiveBet: (state, action) => {
      state.activeBet = action.payload;
    },
    setPreBet: (state, action) => {
      state.preBet = action.payload;
    },
  },
});

export const { setPreBet, setActiveBet } = preBetsSlice.actions;

export default preBetsSlice.reducer;

import { useTonAddress } from "@tonconnect/ui-react";

import ConnectWalletPage from "./ConnectWalletPage";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Bets } from "../containers/Bets";
import { BetTypes } from "../containers";

const MyBetsPage = () => {
  const [autoScrollToTop, setAutoScrollToTop] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (autoScrollToTop) {
      window.scrollTo(0, 0); // Fallback to window.scrollTo if needed
      setAutoScrollToTop(false);
    }
  }, [autoScrollToTop, location]);
  return (
    <main>
      <BetTypes />
      <Bets />
    </main>
  );
};
export default MyBetsPage;

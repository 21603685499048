import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";

const ScrollHandler = ({ onScroll }: { onScroll: any }) => {
  useEffect(() => {
    const handleScroll = () => {
      onScroll();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onScroll]);

  return null;
};
export default ScrollHandler;

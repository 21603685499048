import { configureStore } from "@reduxjs/toolkit";
import walletReducer from "./features/wallet/walletSlice";
import categoriesReducer from "./features/categories/categoriesSlice";
import eventsReducer from "./features/events/eventsSlice";
import currentPageReducer from "./features/currentPage/currentPagesSlice";
import typesReducer from "./features/categories/typesSlice";
import userReducer from "./features/user/userSlice";
import betsReducer from "./features/bet/betSlice";
import preBetReducer from "./features/bet/preBetSlice";
import transactionsReducer from "./features/transactions/transactionsSlice";
import favoritesEventsReducer from "./features/events/favoritesEventsSlice";
import betTypeReducer from "./features/bet/betTypeSlice";
import currencyReducer from "./features/currency/currencySlice";
export const store = configureStore({
  reducer: {
    wallet: walletReducer,
    types: typesReducer,
    categories: categoriesReducer,
    events: eventsReducer,
    favoritesEvents: favoritesEventsReducer,
    currentPage: currentPageReducer,
    user: userReducer,
    bets: betsReducer,
    preBet: preBetReducer,
    transactions: transactionsReducer,
    betTypes: betTypeReducer,
    currency: currencyReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
